import { PrintOrientationEnum } from '../enum/print.enum.ts';

/**
 *  Function to change the print orientation "landscape, portrait"
 * @param orientation = portrait | landscape ...
 */
export const setPrintPageOrientation = (orientation: PrintOrientationEnum) => {
  const style = document.createElement('style');
  style.innerHTML = `@page {size: A4 ${orientation}}`;
  style.id = 'page-orientation';
  document.head.appendChild(style);
};
