import LabDetails from '../../../features/lab-details/LabDetails';
import { useAppSelector } from '../../../store/hooks';
import { connectedUserInfoSelector } from '../../../store/users/users.selectors.tsx';

const PreferencesPage = () => {
  const connectedUser = useAppSelector(connectedUserInfoSelector);

  return connectedUser?.email && <LabDetails email={connectedUser.email} />;
};

export default PreferencesPage;
