import i18next from '../../../i18n';
import { TypeColWithNamePropertyPlatform } from '../../../models/datagrid';
import { formatDate } from '../../../utils/utils';
import DateFilter from '@inovua/reactdatagrid-community/DateFilter';
import {
  assignOrderAction,
  changeStepAction,
  changeTagsAction,
  commentsOrderAction,
  editAction,
  deleteAction,
  printProductionSheetAction,
  printDeliverySheetAction,
  toggleManufactureAction,
  unAssignOrderAction
} from '../../../features/datagrid/menu-entry';
import { KPIData, Order, OrdersIndicators } from '../../../models/order';
import {
  Chips,
  DropdownMenu,
  IconButton,
  Tooltip,
  TooltipContent
} from '@platform-storybook/circlestorybook';
import SelectFilter from '@inovua/reactdatagrid-community/SelectFilter/index';
import { WorkflowStepEnum } from '../../../enum/workflow-step';
import { ManufacturingOrder } from '../../../models/manufacturing-order';
import { GingivaShadeEnum, MaterialEnum, ToothShadeEnum } from '../../../enum/component';
import { ColorPropsEnum } from '../../../enum/color.enum';
import styles from '../../../features/datagrid/datagrid-feature.module.scss';
import { getMaterialColor, isOrderChairSide, isInPendingStatus } from '../../../utils/order.utils';
import { TagEnum } from '../../../enum/tag';
import { getColumnOptions } from '../../../features/datagrid/datagrid.utils';
import { SupervisionTabPermissionsType } from '../../../models/connected-user.tsx';

export const kpiSupervision = (indicators: OrdersIndicators): KPIData[] => [
  {
    index: 1,
    label: i18next.t(`order.steps.${WorkflowStepEnum.VALIDATION}`, { ns: 'common' }),
    kpi: indicators?.ordersToValidate,
    to: '/production/validation',
    limit1: 10,
    limit2: 20
  },
  {
    index: 2,
    label: i18next.t(`order.steps.${WorkflowStepEnum.MODELING}`, { ns: 'common' }),
    kpi: indicators?.ordersToDesign,
    to: '/production/design',
    limit1: 10,
    limit2: 20
  },
  {
    index: 3,
    label: i18next.t(`order.steps.${WorkflowStepEnum.MANUFACTURING}`, { ns: 'common' }),
    kpi: indicators?.ordersToManufacture,
    to: '/production/manufacturing',
    limit1: 10,
    limit2: 20
  },
  {
    index: 4,
    label: i18next.t(`order.steps.${WorkflowStepEnum.ASSEMBLY}`, { ns: 'common' }),
    // 0 for now since ordersToAssemble isn't in indicators yet and it fails tests
    // TODO: once ordersToAssemble is in indicators, uncomment the right kpi line and remove the comment above
    kpi: 0,
    // kpi: indicators?.ordersToAssemble,
    to: '/production/assembly'
  },
  {
    index: 5,
    label: i18next.t(`order.steps.${WorkflowStepEnum.CONTROL}`, { ns: 'common' }),
    to: '/production/control',
    kpi: indicators?.ordersToControl,
    limit1: 10,
    limit2: 20
  },
  {
    index: 6,
    label: i18next.t(`order.steps.${WorkflowStepEnum.DELIVERY}`, { ns: 'common' }),
    to: '/production/shipping',
    kpi: indicators?.ordersToShip,
    limit1: 10,
    limit2: 20
  }
];

export const colSubmissionDate: TypeColWithNamePropertyPlatform = {
  ...getColumnOptions(
    'submissionDate',
    i18next.t('datagrid.columns.submissionDate', { ns: 'dashboard' }),
    2
  ),
  maxWidth: 120,
  showColumnMenuTool: false,
  filterEditor: DateFilter,
  filterEditorProps: () => {
    // for range and not in range operators, the index is 1 for the after field
    return {
      dateFormat: i18next.t('date.small', { ns: 'common' }),
      cancelButton: false
    };
  },
  render: ({ value }) => {
    if (value) {
      return typeof value === 'string' ? formatDate(new Date(value)) : '-';
    }
  }
};

export const colCurrentStep = (): TypeColWithNamePropertyPlatform => {
  return {
    ...getColumnOptions('currentStep', i18next.t('datagrid.columns.step', { ns: 'dashboard' }), 2),
    showColumnMenuTool: false,
    cellDOMProps: () => ({
      style: {
        textAlign: 'left',
        fontWeight: 'bold'
      }
    }),
    filterEditor: SelectFilter,
    filterEditorProps: {
      multiple: true,
      wrapMultiple: false,
      dataSource: Object.values(WorkflowStepEnum).map((step) => {
        return {
          id: step,
          label: i18next.t(`order.steps.${step}`, {
            ns: 'common'
          })
        };
      })
    },
    render: ({ value }) => {
      if (value) {
        return (
          <Tooltip>
            <TooltipContent>
              {i18next.t(`tooltip.steps.${value}`, { ns: 'dashboard' })}
              {isInPendingStatus(value) ? (
                <>
                  <br /> {i18next.t('pendingElement.tooltipContent', { ns: 'common' })}
                </>
              ) : (
                ''
              )}
            </TooltipContent>
            <p>
              {i18next.t(`order.steps.${value}`, {
                ns: 'common'
              }) || '-'}
            </p>
          </Tooltip>
        );
      }
    }
  };
};

export const colDashboardMenu = (
  connectedUserPermissions: SupervisionTabPermissionsType,
  changeStepCallback: (data: Order) => void,
  assignCallback: (data: Order) => void,
  unAssignCallback: (data: Order) => Promise<void>,
  toggleManufactureCallback: (data: Order | ManufacturingOrder) => Promise<void>,
  printProductionCallback: (data: Order) => void,
  printDeliveryCallback: (data: Order) => void,
  updateTagsCallback: (data: Order) => void,
  addCommentCallback: (data: Order) => void,
  handleDeleteCallback: (data: Order) => void
): TypeColWithNamePropertyPlatform | undefined => {
  // Do not display the column if user cannot perform any action
  if (
    !connectedUserPermissions.canCreateOrEditOrder &&
    !connectedUserPermissions.canAdministrateOrder
  ) {
    return;
  }

  return {
    ...getColumnOptions('menu', '', 1, false),
    showColumnMenuTool: false,
    maxWidth: 60,
    render: ({ data }) => {
      const availableActionsForUser = [];

      // No actions available on chairside orders
      if (isOrderChairSide(data)) {
        return;
      }

      if (data && connectedUserPermissions.canCreateOrEditOrder) {
        availableActionsForUser.push([editAction]);
      }

      if (data && connectedUserPermissions.canAdministrateOrder) {
        availableActionsForUser.push([
          changeStepAction(data, changeStepCallback),
          !data.assigneeEmail && !data.assigneeName
            ? assignOrderAction(data, assignCallback)
            : unAssignOrderAction(data, unAssignCallback),
          toggleManufactureAction(data, toggleManufactureCallback),
          printProductionSheetAction(data, printProductionCallback),
          printDeliverySheetAction(data, printDeliveryCallback),
          changeTagsAction(data, updateTagsCallback),
          commentsOrderAction(data, addCommentCallback)
        ]);

        availableActionsForUser.push([deleteAction(data, handleDeleteCallback)]);
      }

      if (availableActionsForUser.length) {
        const kebabMenu = (
          <DropdownMenu
            data-cy={`dropdown-menu--${data.orderNumber}`}
            renderTargetButton={({ active }: { active: boolean }) => (
              <IconButton
                data-cy="datagrid-menu"
                faIconClass="ellipsis-vertical"
                isActive={active}
                isDisabled={isInPendingStatus(data.currentStep)}
                radius="full"
              />
            )}
            data={availableActionsForUser}
          />
        );
        if (isInPendingStatus(data.currentStep)) {
          return (
            <Tooltip>
              <TooltipContent>
                {i18next.t('pendingElement.tooltipContent', { ns: 'common' })}
              </TooltipContent>
              {kebabMenu}
            </Tooltip>
          );
        } else {
          return kebabMenu;
        }
      }
    }
  };
};

export const colMaterial: TypeColWithNamePropertyPlatform = {
  ...getColumnOptions(
    'materials',
    i18next.t('datagrid.columns.material', { ns: 'dashboard' }),
    2,
    false
  ),
  showColumnMenuTool: false,
  cellDOMProps: () => ({
    style: {
      textAlign: 'left',
      display: 'flex',
      flexWrap: 'wrap',
      paddingTop: 0,
      paddingBottom: 0
    }
  }),
  render: ({ data }) => {
    if (data.materials?.length > 0) {
      return data.materials.map((material: MaterialEnum) => {
        const materialLabel = getMaterialLabel(material, data.materialStratification);
        return material ? (
          <>
            <Chips
              key={material}
              className={styles['datagrid-feature__chips']}
              firstLabel={materialLabel}
              color={getMaterialColor(material)}
            />
          </>
        ) : (
          '-'
        );
      });
    }
    return '-';
  }
};

export const colShade: TypeColWithNamePropertyPlatform = {
  ...getColumnOptions('shades', i18next.t('datagrid.columns.shade', { ns: 'dashboard' }), 2, false),
  showColumnMenuTool: false,
  cellDOMProps: () => ({
    style: {
      textAlign: 'left',
      display: 'flex',
      flexWrap: 'wrap',
      paddingTop: 0,
      paddingBottom: 0
    }
  }),
  render: ({ data }) => {
    if (data.shades?.length > 0) {
      return data.shades.map((shade: ToothShadeEnum | GingivaShadeEnum) => {
        return (
          <Chips
            key={shade}
            className={styles['datagrid-feature__chips']}
            color={ColorPropsEnum[shade as keyof typeof ColorPropsEnum]}
            firstLabel={i18next.t(`shade.${shade.toUpperCase()}`, { ns: 'component' })}
          />
        );
      });
    }
    return '-';
  }
};

export const colTags = (tags: string[]): TypeColWithNamePropertyPlatform => {
  return {
    ...getColumnOptions('tags', i18next.t('datagrid.columns.tags', { ns: 'dashboard' }), 2, false),
    showColumnMenuTool: false,
    cellDOMProps: () => ({
      style: {
        textAlign: 'left',
        display: 'flex',
        flexWrap: 'wrap',
        paddingTop: 0,
        paddingBottom: 0
      }
    }),
    filterEditor: SelectFilter,
    filterEditorProps: {
      multiple: true,
      wrapMultiple: false,
      dataSource: [TagEnum.CHAIRSIDE as string, ...tags].map((tag) => {
        return {
          id: tag,
          label: tag
        };
      })
    },
    render: ({ data }) => {
      if (data.tags?.length > 0) {
        return data.tags.map((tag: string) => {
          return (
            <Chips
              key={tag}
              className={styles['datagrid-feature__chips']}
              color={ColorPropsEnum.PURPLE_LIGHT}
              firstLabel={tag}
            />
          );
        });
      }
      return '-';
    }
  };
};

export const getMaterialLabel = (material: MaterialEnum, materialStratification?: string) => {
  let materialLabel = material ? i18next.t('material.' + material, { ns: 'component' }) : '-';

  if (material && materialStratification) {
    materialLabel += ` ${i18next.t('material.stratification.' + materialStratification, {
      ns: 'component'
    })}`;
  }

  return materialLabel;
};
