import { ColorPropsEnum } from '../../../../../../enum/color.enum.ts';
import { ManufacturingOrder } from '../../../../../../models/manufacturing-order.tsx';
import { Box, Text, Skeleton, Button } from '@platform-storybook/circlestorybook';
import styles from './start-modeling-modal.module.scss';
import FileManagerContainer from '../../../../../../features/file-manager/FileManagerContainer.tsx';
import { FileManagerOrientationProp } from '../../../../../../features/file-manager/file-manager.enum.ts';
import { useGetOneOrderQuery } from '../../../../../../services/orders-api.services.ts';
import { OrderFile } from '../../../../../../models/order.tsx';
import {
  useDownloadFileFromStorageMutation,
  useLazyGetOneDownloadableFileQuery
} from '../../../../../../services/files-api.services.ts';
import {
  displayFileInViewer,
  loadOrderFilesData
} from '../../../../../../features/file-manager/file.utils.ts';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks.tsx';
import {
  isLoadingFilesSelector,
  orderFileImageToDisplaySelector,
  orderFilesSelector
} from '../../../../../../store/orders/orders.selectors.tsx';
import { useEffect } from 'react';
import {
  getImagesFromOrder,
  isManufacturingOrderOneDesignCompliant
} from '../../../../../../utils/order.utils.ts';
import { useTranslation } from 'react-i18next';
import { ordersActions } from '../../../../../../store/orders/orders.reducer.tsx';
import SkeletonList from '../../../../../../features/skeleton-list/SkeletonList.tsx';

type StartModelingModalProps = {
  order: ManufacturingOrder;
  onLaunchCad: (order: ManufacturingOrder) => void;
};

const StartModelingModal = ({ order, onLaunchCad }: StartModelingModalProps) => {
  // HOOKS
  const { t } = useTranslation(['production']);
  const dispatch = useAppDispatch();

  // SELECTORS
  const orderImages = useAppSelector(orderFilesSelector);
  const fileImageToDisplay = useAppSelector(orderFileImageToDisplaySelector);
  const isLoadingFiles = useAppSelector(isLoadingFilesSelector);

  // RTK Query
  const [getOneDownloadableFile] = useLazyGetOneDownloadableFileQuery();
  const [downloadFromStorage] = useDownloadFileFromStorageMutation();
  const { data: orderInfo, isLoading: isOrderLoading } = useGetOneOrderQuery(order.orderNumber, {
    skip: !order.orderNumber
  });

  // useEffects
  useEffect(() => {
    if (orderInfo) {
      const imagesFiles = getImagesFromOrder(orderInfo);

      if (imagesFiles.length > 0) {
        loadOrderFilesData(
          dispatch,
          getOneDownloadableFile,
          downloadFromStorage,
          orderInfo.orderNumber,
          imagesFiles
        );
      }
    }
  }, [orderInfo]);

  useEffect(() => {
    return () => {
      dispatch(ordersActions.resetFiles());
    };
  }, []);

  useEffect(() => {
    displayFileInViewer(orderImages, orderImages[orderImages?.length - 1], dispatch);
  }, [orderImages]);

  const buttonLabel = isManufacturingOrderOneDesignCompliant(order)
    ? t('startModelingModal.launchCircleOne')
    : t('startModelingModal.launchCircleCad');

  return (
    <>
      <Box color={ColorPropsEnum.WHITE} className={styles['start-modeling-modal__box']}>
        <Text
          label={t('startModelingModal.instructions')}
          size="m"
          bold
          className={styles['start-modeling-modal__header']}
        />
        {isOrderLoading ? (
          <SkeletonList count={3} heightItem="20px" widthItem="100%" />
        ) : (
          <Text
            label={orderInfo?.instructions || t('startModelingModal.noInstructions')}
            data-cy="instruction"
            className={styles['start-modeling-modal__instructions']}
          />
        )}
      </Box>
      <Box color={ColorPropsEnum.WHITE}>
        <Text
          label={t('startModelingModal.images')}
          size="m"
          bold
          className={styles['start-modeling-modal__header']}
        />
        {isOrderLoading ? (
          <Skeleton />
        ) : orderImages.length > 0 ? (
          <FileManagerContainer
            orientation={FileManagerOrientationProp.PORTRAIT}
            filesList={orderImages}
            isReadOnly={true}
            fileImageToDisplay={fileImageToDisplay}
            onClickFileCallback={(selectedFile: OrderFile) => {
              displayFileInViewer(orderImages, selectedFile, dispatch);
            }}
            isViewerLoading={isLoadingFiles}
          />
        ) : (
          <Text data-cy="no-image" label={t('startModelingModal.noImageFile')} />
        )}
      </Box>
      <div className={styles['start-modeling-modal__launch-cad-button']}>
        <Button
          data-cy="start-modeling-button"
          onClick={() => onLaunchCad(order)}
          label={buttonLabel}
          iconLeft="fa-up-right-from-square"
        />
      </div>
    </>
  );
};

export default StartModelingModal;
