import { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Dropdown,
  Fieldset,
  TextareaField,
  TextField,
  CheckboxList,
  Text,
  Dialog
} from '@platform-storybook/circlestorybook';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { Order, OrderFile, OrderForCreation, OrderItemLight } from '../../../models/order';
import { DiagnosticCreation } from '../../../models/patient';
import {
  useCreateOrderItemMutation,
  useCreateOrderMutation,
  useLazyGetOneOrderQuery,
  usePatchOrderMutation,
  useSubmitOrderMutation,
  useUploadPatientFileToOrderMutation,
  useUploadToStorageMutation
} from '../../../services/orders-api.services';
import {
  orderFilesSelector,
  orderItemsSelector,
  orderSelector
} from '../../../store/orders/orders.selectors';
import { diagnosticFromPositionsSelector } from '../../../store/map/map.selectors';
import styles from '../order-form.module.scss';
import useForm from '../../../utils/useForm';
import {
  getDeliveryDelayFromDentistEmail,
  getNameFromEmail,
  isSupervisor
} from '../../../utils/utils';
import { ordersActions } from '../../../store/orders/orders.reducer';
import { useGetEstimatedDeliveryDateMutation } from '../../../services/products-api.services';
import moment from 'moment/moment';
import { StringObject } from '../../../models/common';
import { OrderForUpdate } from '../../../models/order';
import {
  useGetAllDentistsQuery,
  useGetConnectedUserQuery
} from '../../../services/users-api.services';
import { getDentistsListForDropdownSelector } from '../../../store/users/users.selectors';
import { ColorPropsEnum } from '../../../enum/color.enum';
import {
  getOrderFilesToDelete,
  getOrderFilesToUpload,
  getOrderItemsToCreate,
  getOrderItemsToDelete,
  mapItemToItemForCreation
} from '../utils';
import { WorkFlowStepPreModelingEnum } from '../../../enum/workflow-step';
import { getCreateFormInitValues, getEditFormInitValues } from './finalisation-form';
import {
  areSameFiles,
  uploadFilesToBackEnd,
  uploadToStorages
} from '../../file-manager/file.utils';
import { CheckboxStringItem } from '../../../models/form';

type Props = {
  submitCallback: (orderNumber: string) => void;
  previousCallback: () => void;
  initialOrderInfo?: Order;
};
const FinalisationForm = ({ submitCallback, previousCallback, initialOrderInfo }: Props) => {
  const isEditMode = !!initialOrderInfo;
  const { t } = useTranslation(['order']);
  const dispatch = useAppDispatch();
  const orderToCreate = useAppSelector(orderSelector);
  const orderItemsToCreate = useAppSelector(orderItemsSelector);
  const orderFiles = useAppSelector(orderFilesSelector);
  const diagnosticFromPositions = useAppSelector(diagnosticFromPositionsSelector);

  const { data: connectedUser } = useGetConnectedUserQuery();
  const isCurrentUserSupervisor = useMemo(
    () => (connectedUser?.role ? isSupervisor(connectedUser.role) : false),
    [connectedUser]
  );
  const [
    createOrder,
    { isSuccess: isOrderCreated, isError: isCreateOrderError, data: createdOrder }
  ] = useCreateOrderMutation();
  const [createOrderItem, { isError: isCreateOrderItemError }] = useCreateOrderItemMutation();
  const [
    patchOrder,
    { isSuccess: isSuccessPatchOrder, isError: isPatchOrderError, data: patchedOrder }
  ] = usePatchOrderMutation();
  const [submitOrder, { isSuccess: isOrderSubmitted, isError: isOrderSubmitError }] =
    useSubmitOrderMutation();
  const [getEstimatedDeliveryDate, { data: deliveryDate }] = useGetEstimatedDeliveryDateMutation();
  const [getOneOrder, { data: pollingOrder }] = useLazyGetOneOrderQuery({
    pollingInterval: 500
  });

  const [uploadToOrder, { isError: isUploadToOrderError }] = useUploadPatientFileToOrderMutation();
  const [uploadToStorage, { isError: isUploadToStorageError }] = useUploadToStorageMutation();

  const { data: dentists, isLoading: isLoadingDentists } = useGetAllDentistsQuery();
  const dentistsListForDropdown = useAppSelector(getDentistsListForDropdownSelector());

  const [isCreating, setIsCreating] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isDialogCreateOrder, setIsDialogCreateOrder] = useState(false);
  // When submitting we need to know how many files/items need to be uploaded, created or deleted,
  // to know that everything went well and to redirect the user to another page
  const [numberOfFilesToUpload, setNumberOfFilesToUpload] = useState<number>(0);
  // When editing an order, we need to keep track of what has been updated, so we can
  const [uploadedFilesNumber, setUploadedFilesNumber] = useState<number>(0);

  useEffect(() => {
    if (
      isCreateOrderError ||
      isCreateOrderItemError ||
      isPatchOrderError ||
      isOrderSubmitError ||
      isUploadToOrderError ||
      isUploadToStorageError
    ) {
      setIsCreating(false);
      setIsUpdating(false);
    }
  }, [
    isCreateOrderError,
    isCreateOrderItemError,
    isPatchOrderError,
    isOrderSubmitError,
    isUploadToOrderError,
    isUploadToStorageError
  ]);

  useEffect(() => {
    if (isOrderCreated) {
      setNumberOfFilesToUpload(orderFiles.length);
      if (createdOrder.orderNumber && orderItemsToCreate?.length) {
        uploadFilesAndCreateOrderItems(
          createdOrder.orderNumber,
          orderItemsToCreate as OrderItemLight[]
        );
      }
    }
  }, [isOrderCreated]);

  useEffect(() => {
    if (
      isOrderCreated &&
      numberOfFilesToUpload > 0 &&
      uploadedFilesNumber === numberOfFilesToUpload
    ) {
      submitOrder(createdOrder.orderNumber);
    }
  }, [isOrderCreated, uploadedFilesNumber]);

  useEffect(() => {
    if (isOrderSubmitted && createdOrder) {
      getOneOrder(createdOrder.orderNumber);
    }
  }, [isOrderSubmitted]);

  useEffect(() => {
    if (
      isSuccessPatchOrder &&
      patchedOrder?.patient?.diagnostic?.patientFiles &&
      patchedOrder.patient.diagnostic.patientFiles.length
    ) {
      // patientFiles received from backend does not contain blob file => we need to reset data to upload the files just after
      const patientFilesToUpload = patchedOrder.patient.diagnostic.patientFiles
        .filter((file) => file.uploadUrl)
        .map((file) => {
          const data = orderFiles?.find((orderFile) => file && areSameFiles(orderFile, file))?.data;
          return { ...file, data };
        });
      uploadToStorages(
        dispatch,
        uploadToStorage,
        patientFilesToUpload,
        patientFilesToUpload,
        setUploadedFilesNumber
      );
    }
  }, [isSuccessPatchOrder]);

  useEffect(() => {
    if (isSuccessPatchOrder && uploadedFilesNumber === numberOfFilesToUpload) {
      setIsUpdating(false);
      submitCallback(patchedOrder.orderNumber);
    }
  }, [isSuccessPatchOrder, uploadedFilesNumber, numberOfFilesToUpload]);

  useEffect(() => {
    if (deliveryDate) {
      const formattedDeliveryDate = moment(deliveryDate?.estimatedDeliveryDate).format(
        'YYYY-MM-DD'
      );
      handleSelect(formattedDeliveryDate, 'expectedDate');
    }
  }, [deliveryDate]);

  const uploadFilesAndCreateOrderItems = async (
    orderNumber: string,
    orderItems: OrderItemLight[]
  ): Promise<void> => {
    for (const orderItem of orderItems) {
      const item = mapItemToItemForCreation(orderItem);
      await createOrderItem({ orderNumber, item });
    }
    // at least one item should be created before uploading
    uploadFiles(orderNumber, orderFiles);
  };

  const uploadFiles = async (
    orderNumber: string,
    orderFilesToUpload: OrderFile[]
  ): Promise<void> => {
    if (orderNumber && orderFilesToUpload?.length) {
      await uploadFilesToBackEnd(
        dispatch,
        uploadToOrder,
        uploadToStorage,
        orderFilesToUpload,
        orderNumber,
        setUploadedFilesNumber
      );
    }
  };

  const handlePrevious = () => {
    dispatch(
      ordersActions.setOrder({
        ...orderToCreate,
        dentistEmail: values.dentistEmail as string,
        expectedDate: values.expectedDate as Date,
        instructions: values.instructions as string
      })
    );
    previousCallback();
  };

  const submit = async () => {
    // Edit
    if (isEditMode) {
      const currentOrderFilesToUpload = getOrderFilesToUpload(orderFiles);
      const currentOrderFilesToDelete = getOrderFilesToDelete(
        orderFiles,
        initialOrderInfo?.patient?.diagnostic?.patientFiles
      );
      const currentOrderItemsToCreate = getOrderItemsToCreate(orderItemsToCreate);
      const currentOrderItemsToDelete = getOrderItemsToDelete(
        initialOrderInfo?.items,
        orderItemsToCreate
      );
      setIsUpdating(true);
      let orderToUpdate: OrderForUpdate = {
        orderNumber: initialOrderInfo.orderNumber,
        ...orderToCreate,
        expectedDate: values.expectedDate as Date,
        instructions: values.instructions as string,
        modifiedByLab: true,
        patient: {
          ...orderToCreate.patient,
          diagnostic: {
            ...(diagnosticFromPositions as DiagnosticCreation),
            patientFiles: orderFiles
          }
        },
        tags: (values.tags as Array<CheckboxStringItem>)
          ?.filter((tag) => tag.isChecked)
          ?.map((tag) => tag.value)
      };
      if (currentOrderFilesToDelete?.length || currentOrderFilesToUpload?.length) {
        setNumberOfFilesToUpload(orderFiles.length);
        orderToUpdate = {
          ...orderToUpdate,
          patient: {
            ...orderToUpdate.patient,
            diagnostic: { ...orderToUpdate?.patient?.diagnostic, patientFiles: orderFiles }
          }
        };
      }
      if (currentOrderItemsToDelete?.length || currentOrderItemsToCreate?.length) {
        orderToUpdate = {
          ...orderToUpdate,
          items: orderItemsToCreate.map((item) => mapItemToItemForCreation(item))
        };
      }

      // Orders to update should never have dentist info, so we remove it
      if (orderToUpdate.dentistEmail) {
        delete orderToUpdate.dentistEmail;
      }

      if (orderToUpdate.dentistName) {
        delete orderToUpdate.dentistName;
      }

      // Patch order
      await patchOrder(orderToUpdate);
    }
    // Create
    else {
      const order: OrderForCreation = {
        ...orderToCreate,
        dentistName: getNameFromEmail(dentists?.data, values.dentistEmail as string),
        dentistEmail: values.dentistEmail as string,
        clinicName: 'clinic',
        clinicId: 94,
        toManufacture: true,
        expectedDate: values.expectedDate as Date,
        instructions: values.instructions as string,
        tags: (values.tags as Array<CheckboxStringItem>)
          ?.filter((tag) => tag.isChecked)
          ?.map((tag) => tag.value),
        patient: { diagnostic: diagnosticFromPositions as DiagnosticCreation, reference: 'REF' },
        labId: connectedUser?.laboratory?.id as number,
        labName: connectedUser?.laboratory?.name as string,
        dentistDeliveryDelay: getDeliveryDelayFromDentistEmail(
          dentists?.data,
          values.dentistEmail as string
        )
      } as OrderForCreation;
      setIsCreating(true);
      await createOrder(order);
    }
  };

  const validateAllFieldsRequired = (): StringObject => {
    const newErrors: StringObject = {};
    Object.keys(values).forEach((key) => {
      if (!values[key] && key !== 'instructions') {
        newErrors[key] = 'empty';
      }
    });
    return newErrors;
  };
  const tags: Array<CheckboxStringItem> = Object.values(connectedUser?.laboratory?.tags || []).map(
    (tag) => {
      return {
        value: tag,
        label: tag,
        // We must explicitly set a boolean here cause isChecked is required in storybook
        isChecked: !!initialOrderInfo?.tags?.includes(tag)
      } as CheckboxStringItem;
    }
  );

  const { values, errors, handleSubmit, handleChange, handleBlur, handleSelect, handleCheck } =
    useForm(
      isEditMode
        ? getEditFormInitValues(initialOrderInfo, tags)
        : getCreateFormInitValues(orderToCreate, tags),
      submit,
      validateAllFieldsRequired
    );

  useEffect(() => {
    if (orderItemsToCreate?.length > 0) {
      const productIds = orderItemsToCreate.map((item) => item.product.id);
      let dentistDeliveryDelay = undefined;

      if (values.dentistEmail) {
        dentistDeliveryDelay = getDeliveryDelayFromDentistEmail(
          dentists?.data,
          values.dentistEmail as string
        );
      }

      getEstimatedDeliveryDate({ productIds, dentistDeliveryDelay });
    }
  }, [values.dentistEmail]);

  useEffect(() => {
    // TODO Remove this bullshit piece of code when backend will send message with broker to frontend https://gitlab.com/anatoscope/circle/dev/platform/pub-sub/-/issues/5
    if (pollingOrder?.currentStep === WorkFlowStepPreModelingEnum.VALIDATION) {
      setIsCreating(false);
      if (createdOrder) submitCallback(createdOrder.orderNumber);
    }
  }, [pollingOrder]);

  return (
    <Box color={ColorPropsEnum.WHITE} className={styles['order-form__box']}>
      <form
        onSubmit={(event) => {
          event.preventDefault();
          setIsDialogCreateOrder(true);
        }}
        className={styles['order-form__box__form']}>
        <Dialog
          title={t(`dialog.${isEditMode ? 'updateOrder' : 'createOrder'}.text`, {
            orderNumber: initialOrderInfo?.orderNumber
          })}
          isOpened={isDialogCreateOrder}
          cancelButtonLabel={t('action.cancel', { ns: 'common' })}
          confirmButtonLabel={t(`action.${isEditMode ? 'update' : 'create'}`, {
            ns: 'common'
          })}
          onCancel={() => setIsDialogCreateOrder(false)}
          onConfirm={handleSubmit}
          isLoading={isCreating || isUpdating}
        />
        <Fieldset
          className={[
            styles['order-form__box__form__content'],
            styles['order-form__box__form__content__inputs']
          ].join(' ')}>
          <Dropdown
            data-cy={'dentistDropdown'}
            className={styles['order-form__box__form__content__inputs__dropdown']}
            label={t('createOrder.finalization.dentist.label')}
            data={dentistsListForDropdown}
            value={values?.dentistEmail}
            size="s"
            placeholder={t('createOrder.finalization.dentist.placeholder')}
            onChange={(newValue: string) => {
              handleSelect(newValue, 'dentistEmail');
            }}
            helperText={errors?.dentistEmail && t('createOrder.finalization.dentist.mandatory')}
            variant={errors?.dentistEmail ? ColorPropsEnum.DANGER : ColorPropsEnum.DEFAULT}
            isLoading={isLoadingDentists}
            isSearchable={true}
            isDisabled={isEditMode}
          />
          <TextField
            label={t('createOrder.finalization.expectedDate')}
            value={values.expectedDate}
            id="expectedDate"
            name="expectedDate"
            type="date"
            data-cy={'order-expectedDate'}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={
              errors?.expectedDate ? t('createOrder.finalization.expectedDateMandatory') : undefined
            }
            min={moment().format('YYYY-MM-DD')}
            className={styles['order-form__box__form__content__inputs__date']}
            variant={errors?.expectedDate ? ColorPropsEnum.DANGER : ColorPropsEnum.DEFAULT}
            isDisabled={isEditMode && !isCurrentUserSupervisor}
          />
          <TextareaField
            label={t('createOrder.finalization.instructions')}
            value={values.instructions}
            id="instructions"
            name="instructions"
            onChange={handleChange}
            inputHeight="xl"
            className={styles['order-form__box__form__content__textarea__instructions']}
            data-cy={'order-instructions'}
          />
          {Object.keys(connectedUser?.laboratory?.tags || []).length > 0 ? (
            <CheckboxList
              title={t('updateTagsModal.tags', { ns: 'dashboard' })}
              name="tags"
              data={values.tags}
              onClick={handleCheck}
              data-cy={'order-tags'}
            />
          ) : (
            <Text label={t('updateTagsModal.noTag', { ns: 'dashboard' })} />
          )}
        </Fieldset>
        <div className="form__submit-button form__submit-button--double">
          <Button
            label={t('action.previous', { ns: 'common' })}
            category="tertiary"
            onClick={handlePrevious}
            iconLeft="fa-chevron-left"
          />
          <Button
            data-cy="finalisation-submit-button"
            label={t(
              isEditMode
                ? 'createOrder.finalization.submit.edit'
                : 'createOrder.finalization.submit.create'
            )}
            type="submit"
            iconLeft={isEditMode ? 'fa-pen-to-square' : 'plus'}
            variant="success"
            isDisabled={isLoadingDentists}
            isLoading={isCreating || isUpdating}
          />
        </div>
      </form>
    </Box>
  );
};

export default FinalisationForm;
