import UserDetails from '../../../features/user-details/UserDetails';
import { useAppSelector } from '../../../store/hooks';
import { UserDetailsRefererEnum } from '../../../enum/user';
import { connectedUserInfoSelector } from '../../../store/users/users.selectors.tsx';

const ProfilePage = () => {
  const connectedUser = useAppSelector(connectedUserInfoSelector);

  return (
    connectedUser?.email && (
      <UserDetails email={connectedUser.email} referer={UserDetailsRefererEnum.PROFILE_PAGE} />
    )
  );
};

export default ProfilePage;
