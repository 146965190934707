import {
  getDentalArchFromPositions,
  getOrderedRangesFromPositions
} from '../order-manager/teeth-map/utils';
import { Chips } from '@platform-storybook/circlestorybook';
import { PositionKey } from '../../models/position';

type props = {
  teethPositions: PositionKey[];
};

export const TeethPositions = ({ teethPositions }: props) => {
  const buildTeethRanges = (teethPositions: PositionKey[]): string[] => {
    const result: string[] = [];
    const dentalArch = getDentalArchFromPositions(teethPositions);
    const teethPositionsSorted = getOrderedRangesFromPositions(teethPositions, dentalArch);

    teethPositionsSorted.forEach((range) => {
      if (range.length > 1) {
        result.push([range[0], range.slice(-1)].join('-'));
      }

      if (range.length === 1) {
        result.push(`${range[0]}`);
      }
    });

    return result;
  };
  return buildTeethRanges(teethPositions).map((range) => (
    <Chips
      size="s"
      data-cy={`position-${range}`}
      firstIcon={'fa-tooth'}
      key={`pos-${range}`}
      firstLabel={range}
    />
  ));
};
