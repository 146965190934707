import { api } from './api';
import { OrderFile } from '../models/order';
import { Family } from '../enum/product.enum.ts';
import { Material } from '../models/common-types.tsx';

export const filesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getOneDownloadableFile: builder.query<OrderFile, { orderNumber: string; fileId: number }>({
      query: (arg: { orderNumber: string; fileId: number }) => {
        const { orderNumber, fileId } = arg;
        return {
          url: `/orders/${orderNumber}/patient-files/${fileId}`,
          method: 'GET'
        };
      }
    }),
    getProductionZipFile: builder.query<
      string,
      { orderNumber: string; productFamily: Family; labName: string }
    >({
      query: (arg: { orderNumber: string; productFamily: Family; labName: string }) => ({
        url: `/files?case=${arg.orderNumber}&lab=${arg.labName}&product_type=${arg.productFamily}`,
        responseHandler: 'text'
      })
    }),
    downloadFileFromStorage: builder.mutation<BlobPart, { url: string }>({
      query: (arg: { url: string }) => {
        const { url } = arg;
        const encodedUrl = `${url.substring(0, url.lastIndexOf('/') + 1)}${encodeURIComponent(
          url.substring(url.lastIndexOf('/') + 1)
        )}`;
        return {
          url: encodedUrl,
          method: 'GET',
          responseHandler: async (response: Response) => await response.blob()
        };
      }
    }),
    getProductionFilesDownloadUrl: builder.query<{ downloadUrl: string }, string>({
      query: (orderNumber: string) => ({
        url: `/orders/${orderNumber}/production-files`,
        method: 'GET'
      })
    }),
    getPatientFilesDownloadUrl: builder.query<{ downloadUrl: string }, string>({
      query: (orderNumber: string) => ({
        url: `/orders/${orderNumber}/patient-files`,
        method: 'GET'
      })
    }),
    uploadProductionFile: builder.mutation<
      string,
      {
        orderNumber: string;
        file: OrderFile;
        labName: string;
        teethMaterial: Material;
        productFamily: string;
      }
    >({
      query: ({
        orderNumber,
        file,
        labName,
        teethMaterial,
        productFamily
      }: {
        orderNumber: string;
        file: OrderFile;
        labName: string;
        teethMaterial: Material;
        productFamily: string;
      }) => ({
        url: `/files?lab=${labName}&ordernumber=${orderNumber}&type=teeth&filename=${file.fileName}&extension=${file.extension}&material=${teethMaterial}&product_type=${productFamily}`,
        method: 'POST',
        body: file,
        responseHandler: 'text'
      })
    })
  })
});

export const {
  useLazyGetOneDownloadableFileQuery,
  useDownloadFileFromStorageMutation,
  useLazyGetProductionZipFileQuery,
  useLazyGetProductionFilesDownloadUrlQuery,
  useGetPatientFilesDownloadUrlQuery,
  useLazyGetPatientFilesDownloadUrlQuery,
  useUploadProductionFileMutation
} = filesApi;
