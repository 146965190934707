export enum TeethMode {
  SINGLE_RANGE = 'single-range',
  MULTI_RANGE = 'multi-range',
  SINGLE_TOOTH = 'single-tooth',
  MULTI_TEETH = 'multi-teeth',
  FULL_ARCH = 'full-arch',
  MULTI_ARCH = 'multi-arch'
}

enum StructureSelectionMode {
  STRUCTURE_TOOTH = 'structure-tooth'
}

export const TeethSelectionMode = { ...TeethMode, ...StructureSelectionMode };

export type TeethSelectionModeType = (typeof TeethSelectionMode)[keyof typeof TeethSelectionMode];

export enum StumpMode {
  NONE = 'none',
  ALWAYS = 'always',
  SELECTABLE = 'selectable',
  ONLY = 'only'
}

export enum InjectionMode {
  NONE = 'NONE',
  SELECTABLE = 'SELECTABLE'
}

export enum Family {
  REMOV = 'REMOV',
  FIXED = 'FIXED',
  IMPLANT = 'IMPLANT',
  GUARDS = 'GUARDS',
  OCCLUSION_RIMS = 'OCR'
}

export enum ProductCategory {
  UNIT = 'UNIT',
  BRIDGE = 'BRIDGE',
  PROVISIONAL = 'PROV',
  DEFINITIVE = 'DEFINITIVE',
  BAR = 'BAR',
  SNAP_ON_DENTURES = 'SNAP_ON_DENTURES'
}

export enum ProductClassEnum {
  PERFORM = 'PERFORM',
  PREMIUM = 'PREMIUM',
  STANDARD = 'STANDARD'
}

export enum ProductSubCategoryEnum {
  WEDGE = 'WEDGE',
  BI_MATERIAL = 'BI_MATERIAL',
  MONOLITHIC_ESTHETIC = 'MONOLITHIC_ESTHETIC',
  MONOLITHIC_MONOSHADE = 'MONOLITHIC_MONOSHADE',
  WITH_RESERVOIR = 'WITH_RESERVOIR',
  WITHOUT_RESERVOIR = 'WITHOUT_RESERVOIR'
}

export enum CardDisplayMode {
  PRINT = 'print',
  PRINT_DELIVERY = 'print-delivery',
  DEFAULT = 'default',
  TOOLTIP = 'tooltip'
}
