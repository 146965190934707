import PublicApp from './pages/public/PublicApp';
import ErrorPage from './pages/error-page/ErrorPage';
import ProtectedRoute from './auth/ProtectedRoute';
import LoginPage from './pages/public/login-page/LoginPage';
import NotFoundPage from './pages/not-found-page/NotFoundPage';
import PrivateApp from './pages/private/PrivateApp';
import DashboardPage from './pages/private/dashboard-page/DashboardPage';
import ProductionPage from './pages/private/production-page/ProductionPage';
import ValidationPage from './pages/private/production-page/tabs-page/validation/ValidationPage';
import ManufacturingPage from './pages/private/production-page/tabs-page/manufacturing/ManufacturingPage';
import AssemblyPage from './pages/private/production-page/tabs-page/assembly/AssemblyPage';
import ShippingPage from './pages/private/production-page/tabs-page/shipping/ShippingPage';
import DesignPage from './pages/private/production-page/tabs-page/design/DesignPage';
import ControlPage from './pages/private/production-page/tabs-page/control/ControlPage';
import DentistPage from './pages/private/dentist-page/DentistPage';
import BillingPage from './pages/private/billing-page/BillingPage';
import ProfilePage from './pages/private/profile-page/ProfilePage';
import PreferencesPage from './pages/private/preferences-page/PreferencesPage';
import DentistDetailPage from './pages/private/dentist-page/dentist-detail-page/DentistDetailPage';
import { Navigate, Route, Routes } from 'react-router-dom';
import CreateDentistPage from './pages/private/dentist-page/create-dentist-page/CreateDentistPage';
import { useAppSelector } from './store/hooks';
import { connectedUserSelector, isAuthenticatedSelector } from './store/auth/auth.selectors';
import StreamingPage from './pages/private/streaming-page/StreamingPage';
import EditOrderPage from './pages/private/edit-order-page/EditOrderPage';
import ResetPasswordPage from './pages/public/reset-password-page/ResetPasswordPage';
import OrderDetailPage from './pages/private/order-detail-page/OrderDetailPage';
import CreateOrderPage from './pages/private/create-order-page/CreateOrderPage';
import { DesignerRole } from './enum/user.ts';

const RoutesLab = () => {
  // Redux authentication boolean and Firebase state of authentication to improve the security.
  const isAuthenticated = useAppSelector(isAuthenticatedSelector);
  const connectedUser = useAppSelector(connectedUserSelector);
  const connectedUserHomepage =
    connectedUser?.role === DesignerRole.DESIGNER ? '/production/design' : '/dashboard';

  return (
    <Routes>
      <Route path="/" element={<Navigate to={connectedUserHomepage} />} />
      <Route element={<PublicApp />} errorElement={<ErrorPage />}>
        <Route
          path="login"
          element={
            <ProtectedRoute redirectPath={connectedUserHomepage} isAllowed={!isAuthenticated}>
              <LoginPage />
            </ProtectedRoute>
          }
        />
        <Route path="reset-password" element={<ResetPasswordPage />} />
      </Route>
      <Route
        element={
          <ProtectedRoute redirectPath="/login" isAllowed={isAuthenticated}>
            <PrivateApp displayLayout={false} />
          </ProtectedRoute>
        }
        errorElement={<ErrorPage />}>
        <Route path="streaming/:orderNumber" element={<StreamingPage />} />
      </Route>
      <Route
        element={
          <ProtectedRoute redirectPath="/login" isAllowed={isAuthenticated}>
            <PrivateApp displayLayout={true} />
          </ProtectedRoute>
        }
        errorElement={<ErrorPage />}>
        <Route path="dashboard" element={<DashboardPage />} />
        <Route path="/order/create" element={<CreateOrderPage />} />
        <Route path="/order/:orderNumber/edit" element={<EditOrderPage />} />
        <Route path="/order/:orderNumber/detail" element={<OrderDetailPage />} />
        <Route path="production" element={<Navigate to="/production/validation" />} />
        <Route
          path="production/validation"
          element={
            <ProductionPage>
              <ValidationPage />
            </ProductionPage>
          }
        />
        <Route
          path="production/design"
          element={
            <ProductionPage>
              <DesignPage />
            </ProductionPage>
          }
        />
        <Route
          path="production/manufacturing"
          element={
            <ProductionPage>
              <ManufacturingPage />
            </ProductionPage>
          }
        />
        <Route
          path="production/assembly"
          element={
            <ProductionPage>
              <AssemblyPage />
            </ProductionPage>
          }
        />
        <Route
          path="production/shipping"
          element={
            <ProductionPage>
              <ShippingPage />
            </ProductionPage>
          }
        />
        <Route
          path="production/control"
          element={
            <ProductionPage>
              <ControlPage />
            </ProductionPage>
          }
        />
        <Route path="dentist" element={<DentistPage />} />
        <Route path="dentist/detail/:email" element={<DentistDetailPage />} />
        <Route path="billing" element={<BillingPage />} />
        <Route path="profile" element={<ProfilePage />} />
        <Route path="preferences" element={<PreferencesPage />} />
        <Route path="dentist/create" element={<CreateDentistPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Route>
    </Routes>
  );
};

export default RoutesLab;
