import { ActionReducerMapBuilder, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  MapClickPayload,
  MapContext,
  MapReducer,
  Position,
  ProductAttributesForPosition
} from '../../models/map';
import {
  addTeethStructureToMap,
  isUpperArch,
  removeTeethStructureToMap
} from '../../features/order-manager/teeth-map/utils';
import {
  ComponentType,
  DentalArchEnum,
  GingivaShadeEnum,
  MaterialEnum,
  ToothShadeEnum,
  ToothShapeEnum,
  ToothStructureEnum
} from '../../enum/component';
import { SelectionContextEnum, ToolEnum, ToothSelectionEnum } from '../../enum/map.enum';
import {
  sortedLowerPositionsArray,
  sortedPositionsString,
  sortedUpperPositionsArray
} from '../../enum/position.enum';
import { PositionKey, PositionKeyString } from '../../models/position';
import {
  StumpMode,
  TeethMode,
  TeethSelectionMode,
  TeethSelectionModeType
} from '../../enum/product.enum';
import {
  computeActivePrdZoneLinkPos,
  computeForbiddenPositionsSameProductOnArch,
  computeInitForbiddenPosSingleRange,
  computeMultiRangeForbiddenPosition,
  computeRangeKeys,
  computeResetZoneLinkPos,
  computeSelectionToothPositions,
  computeSingleRangeForbiddenPositions,
  getZoneKeys,
  computeZoneLinkPosByPrd,
  getTeethBetweenZoneMultirange
} from './map.utils';
import { OrderItem, OrderItemComponent } from '../../models/order';
import { FamilyColorEnum } from '../../enum/color.enum';
import { getItemComponentAttribute } from '../../utils/order.utils';

const initialPosition: Position = {
  structureSelection: ToothSelectionEnum.UNSELECTABLE,
  teethStructure: undefined,
  bubble: undefined,
  teethShade: undefined, // not defining a default teethShade, stb handles it
  gingivaShade: undefined,
  shape: undefined,
  frame: undefined,
  missing: false,
  extract: false,
  selection: ToothSelectionEnum.UNSELECTABLE,
  notation: undefined,
  arch: undefined,
  zone_link: undefined,
  productIds: [],
  svgLayers: [],
  stump: undefined,
  familyColor: undefined // for color line/number of the map
};

const initialMap: MapReducer = {
  positions: {
    '18': {
      ...initialPosition,
      notation: '18',
      arch: DentalArchEnum.UPPER
    },
    '17': {
      ...initialPosition,
      notation: '17',
      arch: DentalArchEnum.UPPER
    },
    '16': {
      ...initialPosition,
      notation: '16',
      arch: DentalArchEnum.UPPER
    },
    '15': {
      ...initialPosition,
      notation: '15',
      arch: DentalArchEnum.UPPER
    },
    '14': {
      ...initialPosition,
      notation: '14',
      arch: DentalArchEnum.UPPER
    },
    '13': {
      ...initialPosition,
      notation: '13',
      arch: DentalArchEnum.UPPER
    },
    '12': {
      ...initialPosition,
      notation: '12',
      arch: DentalArchEnum.UPPER
    },
    '11': {
      ...initialPosition,
      notation: '11',
      arch: DentalArchEnum.UPPER
    },
    '21': {
      ...initialPosition,
      notation: '21',
      arch: DentalArchEnum.UPPER
    },
    '22': {
      ...initialPosition,
      notation: '22',
      arch: DentalArchEnum.UPPER
    },
    '23': {
      ...initialPosition,
      notation: '23',
      arch: DentalArchEnum.UPPER
    },
    '24': {
      ...initialPosition,
      notation: '24',
      arch: DentalArchEnum.UPPER
    },
    '25': {
      ...initialPosition,
      notation: '25',
      arch: DentalArchEnum.UPPER
    },
    '26': {
      ...initialPosition,
      notation: '26',
      arch: DentalArchEnum.UPPER
    },
    '27': {
      ...initialPosition,
      notation: '27',
      arch: DentalArchEnum.UPPER
    },
    '28': {
      ...initialPosition,
      notation: '28',
      arch: DentalArchEnum.UPPER
    },
    '48': {
      ...initialPosition,
      notation: '48',
      arch: DentalArchEnum.LOWER
    },
    '47': {
      ...initialPosition,
      notation: '47',
      arch: DentalArchEnum.LOWER
    },
    '46': {
      ...initialPosition,
      notation: '46',
      arch: DentalArchEnum.LOWER
    },
    '45': {
      ...initialPosition,
      notation: '45',
      arch: DentalArchEnum.LOWER
    },
    '44': {
      ...initialPosition,
      notation: '44',
      arch: DentalArchEnum.LOWER
    },
    '43': {
      ...initialPosition,
      notation: '43',
      arch: DentalArchEnum.LOWER
    },
    '42': {
      ...initialPosition,
      notation: '42',
      arch: DentalArchEnum.LOWER
    },
    '41': {
      ...initialPosition,
      notation: '41',
      arch: DentalArchEnum.LOWER
    },
    '31': {
      ...initialPosition,
      notation: '31',
      arch: DentalArchEnum.LOWER
    },
    '32': {
      ...initialPosition,
      notation: '32',
      arch: DentalArchEnum.LOWER
    },
    '33': {
      ...initialPosition,
      notation: '33',
      arch: DentalArchEnum.LOWER
    },
    '34': {
      ...initialPosition,
      notation: '34',
      arch: DentalArchEnum.LOWER
    },
    '35': {
      ...initialPosition,
      notation: '35',
      arch: DentalArchEnum.LOWER
    },
    '36': {
      ...initialPosition,
      notation: '36',
      arch: DentalArchEnum.LOWER
    },
    '37': {
      ...initialPosition,
      notation: '37',
      arch: DentalArchEnum.LOWER
    },
    '38': {
      ...initialPosition,
      notation: '38',
      arch: DentalArchEnum.LOWER
    }
  },
  mapContext: undefined
};

export const mapSlice = createSlice({
  name: 'map',
  initialState: initialMap,
  reducers: {
    initMapContext: (state, action: PayloadAction<Partial<MapContext>>) => {
      state.mapContext = {
        ...state.mapContext,
        ...action.payload
      } as MapContext;
    },
    resetMapContext: (state) => {
      state.mapContext = undefined;
    },
    resetSelectionTeeth: (state) => {
      Object.keys(state.positions).forEach((positionKey) => {
        state.positions[positionKey].selection = ToothSelectionEnum.UNSELECTABLE;
      });
    },
    setTeethSelectionMode: (state, action: PayloadAction<TeethSelectionModeType>) => {
      state.mapContext = {
        ...state.mapContext,
        teethSelectionMode: action.payload
      } as MapContext;
    },
    toggleToothExtract: (state, action: PayloadAction<string>) => {
      state.positions[action.payload] = {
        ...state.positions[action.payload],
        extract: !state.positions[action.payload].extract,
        missing: false
      };
    },
    toggleToothMissing: (state, action: PayloadAction<PositionKeyString>) => {
      state.positions[action.payload] = {
        ...state.positions[action.payload],
        missing: !state.positions[action.payload].missing,
        extract: false
      };
    },
    addMissingTeethToMap: (state, action: PayloadAction<PositionKey[]>) => {
      const newPositions = { ...state.positions };
      action.payload.forEach((missingToothPosition) => {
        newPositions[missingToothPosition] = {
          ...newPositions[missingToothPosition],
          missing: true,
          extract: false
        };
      });
      state.positions = { ...newPositions };
    },
    addExtractedTeethToMap: (state, action: PayloadAction<PositionKey[]>) => {
      const newPositions = { ...state.positions };
      action.payload.forEach((missingToothPosition) => {
        newPositions[missingToothPosition] = {
          ...newPositions[missingToothPosition],
          missing: false,
          extract: true
        };
      });
      state.positions = { ...newPositions };
    },
    initSelectionTooth: (state) => {
      // Call once before the 1st click on the teethMap
      let forbiddenPositions: Array<PositionKeyString> = [];
      // TODO : Check product compatibility on arch
      switch (state.mapContext?.teethSelectionMode) {
        case TeethSelectionMode.SINGLE_RANGE: {
          // Check the rule minTeeth (is there enough space for the range)
          forbiddenPositions = [
            ...forbiddenPositions,
            ...computeInitForbiddenPosSingleRange(
              state.mapContext?.teethComponentRule?.min,
              state.positions
            )
          ];
          break;
        }
        case TeethSelectionMode.MULTI_RANGE: {
          // It is not possible to add a multirange inside another multirange
          if (
            Object.values(state.positions).find((pos) => pos.zone_link && pos.productIds.length > 0)
          ) {
            forbiddenPositions.push(
              ...getTeethBetweenZoneMultirange(state.positions, DentalArchEnum.UPPER)
            );
            forbiddenPositions.push(
              ...getTeethBetweenZoneMultirange(state.positions, DentalArchEnum.LOWER)
            );
          }
          break;
        }
      }

      // Check de rule allowSameProductOnArch;
      if (!state.mapContext?.productRule.allowSameProductOnArch && state?.mapContext?.productId) {
        const forbiddenPosSamePrdOnArch = computeForbiddenPositionsSameProductOnArch(
          state.positions,
          state.mapContext.productId
        );
        forbiddenPositions = [...forbiddenPositions, ...forbiddenPosSamePrdOnArch];
      }

      // Set 'selected' 'unselectable' 'selectable' on Positions state, depending on forbiddentPositions and productCompatibilities
      if (state.mapContext) {
        state.positions = computeSelectionToothPositions(
          state.mapContext.productId,
          state.positions,
          state.mapContext.productCompatibilities,
          forbiddenPositions
        );
      }
    },
    computeProductSelectionTooth: (state) => {
      // Called after the 1st click on the teethMap
      let forbiddenPositions: Array<PositionKeyString> = [];
      switch (state.mapContext?.teethSelectionMode) {
        case TeethSelectionMode.SINGLE_RANGE: {
          // 1. click occurred on tooth map && context is range-started => first click occurred
          if (
            state.mapContext?.userAction === SelectionContextEnum.RANGE_STARTED &&
            state.mapContext?.start &&
            state.mapContext?.activeArch
          ) {
            forbiddenPositions = computeSingleRangeForbiddenPositions(
              state.mapContext.start,
              state.mapContext?.teethComponentRule?.min || 0,
              state.mapContext?.teethComponentRule?.max || 10,
              state.mapContext.activeArch
            );
          }

          // 2. context is range-end => second click occurred, product end
          if (state.mapContext?.userAction === SelectionContextEnum.RANGE_ENDED) {
            forbiddenPositions = sortedPositionsString;
          }

          // 3. not possible to click on the opposite arch
          if (state?.mapContext?.userAction && state.mapContext?.activeArch) {
            forbiddenPositions = isUpperArch(state.mapContext.activeArch)
              ? [...forbiddenPositions, ...sortedLowerPositionsArray]
              : [...forbiddenPositions, ...sortedUpperPositionsArray];
          }
          break;
        }
        case TeethSelectionMode.MULTI_RANGE: {
          if (state.mapContext?.userAction === SelectionContextEnum.ZONE_STARTED) {
            forbiddenPositions = [
              ...forbiddenPositions,
              ...computeMultiRangeForbiddenPosition(state.positions, state.mapContext)
            ];
          }

          // not possible to click on the opposite arch
          if (state?.mapContext?.userAction && state.mapContext?.activeArch) {
            forbiddenPositions = isUpperArch(state.mapContext.activeArch)
              ? [...forbiddenPositions, ...sortedLowerPositionsArray]
              : [...forbiddenPositions, ...sortedUpperPositionsArray];
          }
          break;
        }
      }

      if (state.mapContext) {
        state.positions = computeSelectionToothPositions(
          state.mapContext.productId,
          state.positions,
          state.mapContext.productCompatibilities,
          forbiddenPositions
        );
      }
    },
    computeMouthSelectionTooth: (state, action: PayloadAction<ToolEnum>) => {
      // Compute selection tooth (for cursors) in diagnostic tool (missing, extract)
      Object.keys(state.positions).forEach((positionKey: string) => {
        if (
          action.payload === ToolEnum.MISSING &&
          state.positions[positionKey].productIds.length > 0
        ) {
          state.positions[positionKey] = {
            ...state.positions[positionKey],
            selection: ToothSelectionEnum.UNSELECTABLE
          };
        } else {
          state.positions[positionKey] = {
            ...state.positions[positionKey],
            selection: ToothSelectionEnum.SELECTABLE
          };
        }
      });
    },
    selectStructurePosition: (state, action: PayloadAction<MapClickPayload>): void => {
      if (action.payload.teethStructure) {
        const isAlreadySelected =
          state.positions[action.payload.notation].teethStructure?.code ===
          action.payload.teethStructure.code;
        if (isAlreadySelected) {
          state.positions[action.payload.notation] = removeTeethStructureToMap(
            state.positions[action.payload.notation],
            action.payload.teethShade
          );
        } else {
          state.positions[action.payload.notation] = addTeethStructureToMap(
            action.payload.teethStructure,
            state.positions[action.payload.notation],
            action.payload.teethShade
          );
        }
      }
    },
    selectSinglePosition: (state, action: PayloadAction<MapClickPayload>): void => {
      const isAlreadySelected =
        state.positions[action.payload.notation].selection === ToothSelectionEnum.SELECTED;
      if (!isAlreadySelected) {
        // If i change my mind, i click on another tooth, reset last tooth selected (only one tooth selected for the product)
        Object.entries(state.positions)
          .filter(([, position]) => position.selection === ToothSelectionEnum.SELECTED)
          .forEach(([positionKey]) => {
            if (positionKey !== action.payload.notation) {
              state.positions[positionKey].selection = initialPosition.selection;
              state.positions[positionKey].stump = initialPosition.stump;
              state.positions[positionKey].teethShade = initialPosition.teethShade;
              state.positions[positionKey].familyColor = initialPosition.familyColor;
              state.positions[positionKey].svgLayers = initialPosition.svgLayers;
              state.positions[positionKey].teethStructure = initialPosition.teethStructure;
            }
          });
      }

      state.positions[action.payload.notation] = {
        ...state.positions[action.payload.notation],
        selection: !isAlreadySelected ? ToothSelectionEnum.SELECTED : initialPosition.selection,
        stump: !isAlreadySelected
          ? action.payload.stumpMode === StumpMode.ALWAYS
          : initialPosition.stump,
        svgLayers: !isAlreadySelected ? action.payload.svgLayers : initialPosition.svgLayers,
        teethShade: !isAlreadySelected ? action.payload.teethShade : initialPosition.teethShade,
        familyColor: !isAlreadySelected ? action.payload.familyColor : initialPosition.familyColor,
        teethStructure: !isAlreadySelected
          ? action.payload.teethStructure
          : initialPosition.teethStructure
      };
      state.mapContext = {
        ...state.mapContext,
        userAction: SelectionContextEnum.SINGLE_TOOTH,
        activeArch: state.mapContext?.activeArch ?? state.positions[action.payload.notation].arch
      } as MapContext;
    },
    startSingleRange: (state, action: PayloadAction<MapClickPayload>) => {
      state.positions[action.payload.notation] = {
        ...state.positions[action.payload.notation],
        selection: ToothSelectionEnum.SELECTED,
        gingivaShade: action.payload.gingivaShade,
        teethShade: action.payload.teethShade,
        frame: action.payload.frameMaterial,
        familyColor: action.payload.familyColor,
        svgLayers: action.payload.svgLayers
      };

      state.mapContext = {
        ...state.mapContext,
        userAction: SelectionContextEnum.RANGE_STARTED, // context: user just opened the range
        start: action.payload.notation,
        activeArch: state.mapContext?.activeArch ?? state.positions[action.payload.notation].arch
      } as MapContext;
    },
    closeSingleRange: (state, action: PayloadAction<MapClickPayload>) => {
      // context: the user closed the range
      const rangeKeys = state?.mapContext?.start
        ? computeRangeKeys(state?.mapContext?.start, action.payload.notation)
        : [];

      Object.keys(state.positions).forEach((positionKey) => {
        if (rangeKeys?.includes(positionKey as PositionKeyString)) {
          state.positions[positionKey] = {
            ...state.positions[positionKey],
            selection: ToothSelectionEnum.SELECTED,
            gingivaShade: action.payload.gingivaShade,
            teethShade: action.payload.teethShade,
            frame: action.payload.frameMaterial,
            missing: false,
            familyColor: action.payload.familyColor,
            svgLayers: action.payload.svgLayers
          };
        }
      });

      state.mapContext = {
        ...state.mapContext,
        userAction: SelectionContextEnum.RANGE_ENDED, // context: user just closed the range
        end: action.payload.notation
      } as MapContext;
    },
    removeSingleRange: (state) => {
      Object.keys(state.positions).forEach((position) => {
        if (state.positions[position].selection === ToothSelectionEnum.SELECTED) {
          state.positions[position].selection = initialPosition.selection;
          state.positions[position].stump = initialPosition.stump;
          state.positions[position].teethShade = initialPosition.teethShade;
          state.positions[position].gingivaShade = initialPosition.gingivaShade;
          state.positions[position].familyColor = initialPosition.familyColor;
          state.positions[position].svgLayers = initialPosition.svgLayers;
          state.positions[position].teethStructure = initialPosition.teethStructure;
        }
      });

      state.mapContext = {
        ...state.mapContext,
        userAction: undefined,
        activeArch: undefined,
        start: undefined,
        end: undefined
      } as MapContext;
    },
    startZone: (state, action: PayloadAction<MapClickPayload>) => {
      state.positions[action.payload.notation] = {
        ...state.positions[action.payload.notation],
        selection: ToothSelectionEnum.SELECTED,
        gingivaShade: action.payload.gingivaShade,
        teethShade: action.payload.teethShade,
        frame: action.payload.frameMaterial,
        familyColor: action.payload.familyColor,
        svgLayers: action.payload.svgLayers
      };

      state.mapContext = {
        ...state.mapContext,
        userAction: SelectionContextEnum.ZONE_STARTED, // context: user just opened the zone of multi range
        start: action.payload.notation,
        activeArch: state.positions[action.payload.notation].arch
      } as MapContext;
    },
    endZone: (state, action: PayloadAction<MapClickPayload>) => {
      const rangeKeys = state.mapContext?.start
        ? computeRangeKeys(state.mapContext?.start, action.payload.notation)
        : [];
      Object.keys(state.positions).forEach((positionKey) => {
        if (rangeKeys?.includes(positionKey as PositionKeyString)) {
          state.positions[positionKey] = {
            ...state.positions[positionKey],
            selection: ToothSelectionEnum.SELECTED,
            gingivaShade: action.payload.gingivaShade,
            teethShade: action.payload.teethShade,
            frame: action.payload.frameMaterial,
            missing: false,
            familyColor: action.payload.familyColor,
            svgLayers: action.payload.svgLayers
          };
        }
      });

      state.mapContext = {
        ...state.mapContext,
        userAction: SelectionContextEnum.ZONE_ENDED, // context: user just closed the zone of multi range
        end: action.payload.notation
      } as MapContext;
    },
    removeZone: (state, action: PayloadAction<MapClickPayload>) => {
      let activeArch: DentalArchEnum | undefined = state.mapContext?.activeArch;

      // context: the user clicked on an already selected position that is not the starting position
      const allPositions = Object.values(state.positions).map((position) => {
        return {
          notation: position.notation,
          selection: position.selection
        };
      }, {}) as Partial<Position>[];
      const zoneKeys = getZoneKeys(allPositions, action.payload.notation);
      zoneKeys?.forEach((positionKey) => {
        state.positions[positionKey.toString()].selection = initialPosition.selection;
        state.positions[positionKey.toString()].stump = initialPosition.stump;
        state.positions[positionKey.toString()].teethShade = initialPosition.teethShade;
        state.positions[positionKey.toString()].gingivaShade = initialPosition.gingivaShade;
        state.positions[positionKey.toString()].familyColor = initialPosition.familyColor;
        state.positions[positionKey.toString()].svgLayers = initialPosition.svgLayers;
        state.positions[positionKey.toString()].teethStructure = initialPosition.teethStructure;
      });

      if (
        !Object.values(state.positions).some((position) => {
          return position.selection === ToothSelectionEnum.SELECTED;
        })
      ) {
        // if there is no more teeth selected, then we reset activeArch
        activeArch = undefined;
      }

      state.mapContext = {
        ...state.mapContext,
        activeArch: activeArch
      } as MapContext;
    },
    setZoneLinkPositions: (state) => {
      // Before, Reset zone link positions
      // Add zone_link to positions for the active product
      state.positions = computeActivePrdZoneLinkPos(state.positions);
    },
    resetZoneLinkPositions: (state) => {
      // Before, Reset zone link positions
      // Add zone_link to positions for the active product
      state.positions = computeResetZoneLinkPos(state.positions);
    },
    removeSaneTeeth: (state, action: PayloadAction<string>) => {
      const arch = state.positions[action.payload].arch;
      Object.keys(state.positions).forEach((positionKey) => {
        if (
          state.positions[positionKey].arch === arch &&
          state.positions[positionKey].selection !== ToothSelectionEnum.SELECTED &&
          state.positions[positionKey].productIds.length === 0 &&
          !state.positions[positionKey].extract
        ) {
          state.positions[positionKey].missing = true;
        }
      });
    },
    setActiveProductShade: (state, action: PayloadAction<ToothShadeEnum>) => {
      Object.keys(state.positions)
        .filter(
          (positionKey) =>
            state.positions[positionKey].selection === ToothSelectionEnum.SELECTED &&
            (!state.positions[positionKey].teethStructure ||
              ![ToothStructureEnum.METAL, ToothStructureEnum.ADJUSTED_METAL].includes(
                state.positions[positionKey].teethStructure.code
              ))
        )
        .forEach((positionKey) => (state.positions[positionKey].teethShade = action.payload));
    },
    setStructurePositionsSelectable: (state) => {
      // Change structure selection only for the current designed product
      Object.keys(state.positions)
        .filter(
          (positionKey) =>
            state.positions[positionKey].selection === ToothSelectionEnum.SELECTED &&
            state.positions[positionKey].structureSelection === ToothSelectionEnum.UNSELECTABLE &&
            !state.positions[positionKey].productIds?.length
        )
        .forEach((positionKey) => {
          state.positions[positionKey].structureSelection = state.positions[positionKey]
            .teethStructure
            ? ToothSelectionEnum.SELECTED
            : ToothSelectionEnum.SELECTABLE;
        });
    },
    resetStructurePositionsSelectable: (state) => {
      Object.keys(state.positions).forEach((positionKey) => {
        state.positions[positionKey].structureSelection = ToothSelectionEnum.UNSELECTABLE;
      });
    },
    setActiveProductShape: (state, action: PayloadAction<ToothShapeEnum>) => {
      Object.keys(state.positions).forEach((positionKey) => {
        if (state.positions[positionKey].selection === ToothSelectionEnum.SELECTED) {
          state.positions[positionKey].shape = action.payload;
        }
      });
    },
    setActiveProductGingiva: (state, action: PayloadAction<GingivaShadeEnum>) => {
      Object.keys(state.positions).forEach((positionKey) => {
        if (state.positions[positionKey].selection === ToothSelectionEnum.SELECTED) {
          state.positions[positionKey].gingivaShade = action.payload;
        }
      });
    },
    setActiveProductFrameMaterial: (state, action: PayloadAction<MaterialEnum>) => {
      Object.keys(state.positions).forEach((positionKey) => {
        if (state.positions[positionKey].selection === ToothSelectionEnum.SELECTED) {
          state.positions[positionKey].frame = action.payload;
        }
      });
    },
    commitProductToMap: (state, action: PayloadAction<ProductAttributesForPosition>) => {
      Object.keys(state.positions).forEach((positionKey) => {
        if (state.positions[positionKey].selection === ToothSelectionEnum.SELECTED) {
          state.positions[positionKey].productIds = [
            ...state.positions[positionKey].productIds,
            action.payload
          ];
          state.positions[positionKey].selection = ToothSelectionEnum.UNSELECTABLE;
          state.positions[positionKey].structureSelection = ToothSelectionEnum.UNSELECTABLE;
        }
      });
    },
    addItemsToMap: (state, action: PayloadAction<OrderItem[]>) => {
      const sortByComponentType = (a: OrderItemComponent, b: OrderItemComponent): number => {
        return a.componentType.localeCompare(b.componentType);
      };
      const newPositions = { ...state.positions };
      action.payload.forEach((item) => {
        item.itemComponents
          ?.filter((itemComponent) => !!itemComponent)
          ?.sort(sortByComponentType)
          .forEach((itemComponent: OrderItemComponent) => {
            const positions = [
              ...(itemComponent?.teethPositions ?? []),
              ...(itemComponent?.stumpPositions ?? []),
              ...(itemComponent?.injectionPositions ?? [])
            ];
            const productUpdate = {
              productId: item.product.id,
              uniqueProductId: `${item.product.id}_${item.id}`,
              teethMode: item.product.teethMode
            };

            const familyColor = FamilyColorEnum[`FAMILY_${item.product.family}`];

            positions.forEach((position) => {
              const posStr = position.toString();
              let statePosition = newPositions[posStr];

              // ProductId
              const idAlreadyExistsAtPosition = statePosition.productIds.some(
                (product) => product.productId === productUpdate.productId
              );
              if (!idAlreadyExistsAtPosition) {
                statePosition.productIds.push(productUpdate);
              }

              // Shade
              if (itemComponent.shade) {
                switch (itemComponent.componentType) {
                  case ComponentType.TOOTH:
                  case ComponentType.PARTIAL_TOOTH:
                    statePosition.teethShade = getItemComponentAttribute(
                      item,
                      itemComponent.componentType
                    )?.shade?.code as ToothShadeEnum;
                    break;
                  case ComponentType.GINGIVA:
                    statePosition.gingivaShade = getItemComponentAttribute(
                      item,
                      ComponentType.GINGIVA
                    )?.shade?.code as GingivaShadeEnum;
                    break;
                }
              }

              // Shape
              if (itemComponent.shape) {
                statePosition.shape = getItemComponentAttribute(item, ComponentType.TOOTH)?.shape
                  ?.code as ToothShapeEnum;
              }

              // Frame
              if (itemComponent.material && itemComponent.componentType === ComponentType.FRAME) {
                statePosition.frame = getItemComponentAttribute(item, ComponentType.FRAME)?.material
                  ?.code as MaterialEnum;
              }

              // Family color
              statePosition.familyColor = familyColor;

              // Stump
              statePosition.stump = (itemComponent?.stumpPositions ?? []).includes(position);

              // Structures
              if (
                itemComponent.structures?.length &&
                itemComponent.componentType === ComponentType.TOOTH
              ) {
                const structure = itemComponent.structures
                  .filter((structure) => structure.positions.includes(position))
                  .map((structure) => structure.structure);
                if (structure?.length) {
                  statePosition = addTeethStructureToMap(structure[0], statePosition);
                }
              }

              // SVG layers
              if (itemComponent?.svgLayer) {
                statePosition.svgLayers?.push(itemComponent.svgLayer);
              }
              newPositions[posStr] = statePosition;
            });
          });
        // Zone links
        if (item.product.teethMode === TeethMode.MULTI_RANGE) {
          state.positions = computeZoneLinkPosByPrd(newPositions, `${item.product.id}_${item.id}`);
        }
      });

      state.positions = { ...newPositions };
    },
    removeActiveProduct: (state) => {
      Object.keys(state.positions).forEach((positionKey) => {
        if (state.positions[positionKey].selection === ToothSelectionEnum.SELECTED) {
          state.positions[positionKey] = {
            ...initialPosition,
            notation: state.positions[positionKey].notation,
            arch: state.positions[positionKey].arch,
            productIds: state.positions[positionKey].productIds
          };
        }
      });
    },
    resetMap: (state) => {
      state.positions = initialMap.positions;
    },
    removeProduct: (state, action: PayloadAction<{ item: OrderItem }>) => {
      const teethPositions = action.payload.item.itemComponents?.find((component) =>
        [ComponentType.TOOTH, ComponentType.PARTIAL_TOOTH].includes(component.componentType)
      )?.teethPositions;

      let propertiesToReset: Array<string> = [];
      action.payload.item.itemComponents?.forEach((itemComponent) => {
        switch (itemComponent.componentType) {
          case ComponentType.TOOTH:
          case ComponentType.PARTIAL_TOOTH: {
            propertiesToReset = [
              ...propertiesToReset,
              'svgLayers',
              'teethShade',
              'teethStructure',
              'bubble',
              'shape',
              'zone_link',
              'productIds',
              'familyColor'
            ];
            break;
          }
          case ComponentType.FRAME: {
            propertiesToReset = [...propertiesToReset, 'frame'];
            break;
          }
          case ComponentType.GINGIVA: {
            propertiesToReset = [...propertiesToReset, 'gingivaShade'];
            break;
          }
        }
      });

      if (action.payload.item.product.stumpMode === StumpMode.ALWAYS) {
        propertiesToReset.push('stump');
      }
      teethPositions
        ?.map((position) => state.positions[`${position}`] as Position)
        .forEach((positionState: Position) =>
          propertiesToReset.forEach((prop) => {
            if (prop === 'productIds') {
              positionState['productIds'] = positionState['productIds'].filter(
                (product) => product.productId !== action.payload.item.product.id
              );
            } else {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              positionState[prop] = initialPosition[prop];
            }
          })
        );
    }
  },
  extraReducers: (builder: ActionReducerMapBuilder<MapReducer>) => {
    builder.addCase('RESET_ALL', () => {
      return { ...initialMap };
    });
  }
});

export const mapActions = mapSlice.actions;
