import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Chips, TeethMap } from '@platform-storybook/circlestorybook';
import styles from './print-production-sheet.module.scss';
import { Order, OrderItem } from '../../models/order';
import { DentalNotation } from '../../enum/user';
import { getSortedItemsToPrint, SortedItemsForPrint } from './print-order';
import {
  computeLineColors,
  computeTeethShades,
  getFormattedLocalizedMomentDate
} from '../order-manager/teeth-map/utils';
import PrintProductList from './PrintProductList';
import { OrderItemCard } from '../order-item-card/OrderItemCard';
import { CardDisplayMode, Family } from '../../enum/product.enum';
import { ColorPropsEnum } from '../../enum/color.enum';
import { isCategoryProvisional } from '../order-form/utils';

interface PrintProductionSheetProps {
  order: Order;
  dentalNotation?: DentalNotation;
  patientMouth?: { [key: string]: Array<number> | Array<Array<number>> };
  existingTeethmap?: ReactElement;
}

const PrintProductionSheet = React.forwardRef<HTMLDivElement, PrintProductionSheetProps>(
  (props, ref) => {
    const { t } = useTranslation(['print']);
    const { order, dentalNotation, patientMouth, existingTeethmap } = props;
    const orderNumberShort = order?.orderNumber?.slice(-3);
    const sortedItems = getSortedItemsToPrint(order?.items);

    const getFamilyChips = (sortedItems: SortedItemsForPrint) => {
      return (
        <div className={styles['print-production-sheet__left__top__family-list']}>
          {Object.keys(sortedItems).map((family) => {
            const categories = sortedItems[family as Family]?.map((categoryContent) => {
              return (
                <div
                  key={`${family}-${categoryContent.category}`}
                  className={styles['print-production-sheet__left__top__family-list__family']}>
                  <Chips
                    firstLabel={t(`families.${family}`, {
                      ns: 'catalog'
                    })}
                    color={
                      ColorPropsEnum[
                        `FAMILY_${family.toUpperCase()}` as keyof typeof ColorPropsEnum
                      ]
                    }
                    size={'s'}
                    secondLabel={t(`categories.${categoryContent.category}`, {
                      ns: 'catalog'
                    })}
                    dashed={isCategoryProvisional(categoryContent.category)}
                  />
                </div>
              );
            });
            return <div key={`family_container_${family}`}>{categories}</div>;
          })}
        </div>
      );
    };

    return (
      <div ref={ref}>
        <div className={styles['print-production-sheet']}>
          <div className={styles['print-production-sheet__left']}>
            <div className={styles['print-production-sheet__left__top']}>
              <div className={styles['print-production-sheet__left__top__header']}>
                <div className={styles['print-production-sheet__left__top__header__dentist']}>
                  <p>{order?.dentistName}</p>
                  <p>{order?.patient?.reference}</p>
                </div>
                <div className={styles['print-production-sheet__left__top__header__order-number']}>
                  {orderNumberShort}
                </div>
                <div className={styles['print-production-sheet__left__top__header__dates']}>
                  <div
                    className={
                      styles['print-production-sheet__left__top__header__dates__departure']
                    }>
                    <p
                      className={styles['print-production-sheet__left__top__header__dates__label']}>
                      {t('dates.departure')}
                    </p>
                    <p
                      className={
                        styles['print-production-sheet__left__top__header__dates__departure__date']
                      }>
                      {getFormattedLocalizedMomentDate('dayMonth', order?.limitShippingDate)}
                    </p>
                  </div>
                </div>
              </div>
              <div className={styles['print-production-sheet__left__top__teeth-map-section']}>
                <div
                  className={
                    styles['print-production-sheet__left__top__teeth-map-section__products']
                  }>
                  <div
                    className={
                      styles[
                        'print-production-sheet__left__top__teeth-map-section__products__section'
                      ]
                    }>
                    {order?.tags && (
                      <p className={styles['print-production-sheet__left__top__tags-list']}>
                        {order.tags.join(', ')}
                      </p>
                    )}
                    {order && sortedItems && <PrintProductList sortedItems={sortedItems} />}
                  </div>
                </div>
                <div
                  className={
                    styles['print-production-sheet__left__top__teeth-map-section__teeth-map']
                  }>
                  {getFamilyChips(sortedItems)}
                  {!existingTeethmap && (
                    <TeethMap
                      patientMouth={patientMouth}
                      notation={dentalNotation ?? DentalNotation.ISO}
                      teethShades={computeTeethShades(order?.items as OrderItem[])}
                      lineAndNumberColors={computeLineColors(order?.items)}
                      displayShadows={false}
                      isStrokeTeeth={true}
                      hiddenBubbles={true}
                    />
                  )}
                  {existingTeethmap}
                </div>
              </div>
              <div className={styles['print-production-sheet__left__top__instruction-section']}>
                <p
                  className={
                    styles['print-production-sheet__left__top__instruction-section__title']
                  }>
                  {t('instructions')}
                </p>
                {order?.instructions ? <>{order.instructions}</> : '-'}
              </div>
            </div>
            <div className={styles['print-production-sheet__left__full-order-number']}>
              {order?.orderNumber}
            </div>
          </div>
          <div className={styles['print-production-sheet__right']}>
            <div className={styles['print-production-sheet__right__dates']}>
              {order?.expectedDate && (
                <div className={styles['print-production-sheet__right__dates__expected']}>
                  <p className={styles['print-production-sheet__right__dates__label']}>
                    {t('dates.expected')}
                  </p>
                  <p>{getFormattedLocalizedMomentDate('dayMonth', order?.expectedDate)}</p>
                </div>
              )}
            </div>
            <div className={styles['print-production-sheet__right__products-details-section']}>
              {order?.items?.map((item: OrderItem) => {
                return (
                  <OrderItemCard key={item.id} item={item} displayMode={CardDisplayMode.PRINT} />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }
);
PrintProductionSheet.displayName = 'PrintOrder';

export default PrintProductionSheet;
