import i18next from '../../../../../i18n';
import {
  FilterProps,
  MenuEntry,
  TypeColWithNamePropertyPlatform
} from '../../../../../models/datagrid';
import {
  BubblePicto,
  Button,
  Chips,
  DropdownMenu,
  IconButton,
  Link,
  Text,
  Timeline,
  TimelineItem,
  Tooltip,
  TooltipContent
} from '@platform-storybook/circlestorybook';
import styles from '../../../../../features/datagrid/datagrid-feature.module.scss';
import SelectFilter from '@inovua/reactdatagrid-community/SelectFilter';
import { CommonTypes } from '../../../../../models/common-types';
import DateFilter from '@inovua/reactdatagrid-community/DateFilter';
import { ComponentType, ManufacturingProcessEnum } from '../../../../../enum/component';
import { WorkflowStepDatagridFilter } from '../../../../../enum/workflow-step';
import moment from 'moment';
import { ManufacturingComponentView } from '../../../../../models/component';
import productionPageStyles from '../../production-page.module.scss';
import { ActionBtn, getUrlEncodedFilter } from '../../../../../features/datagrid/columns';
import { ColorPropsEnum } from '../../../../../enum/color.enum';
import {
  manufacturingStepDisplay,
  manufacturingStepKeysEnum
} from '../../../../../enum/manufacturing-step.enum';
import { isInProgressStep, isPendingStep, isSuccessStep } from './manufacturing-steps';
import StepActions from './step-actions/StepActions';
import { faArrowsRotate } from '@fortawesome/free-solid-svg-icons';
import { ProductForDatagrid } from '../../../../../models/product';
import { getLocalizedProperty } from '../../../../../utils/utils';
import {
  assignManufacturingOrderAction,
  commentsOrderAction,
  getManufacturingProcessEntryActions,
  unAssignManufacturingOrderAction
} from '../../../../../features/datagrid/menu-entry';
import { ToastType } from '../../../../../enum/feedback';
import { feedbackActions } from '../../../../../store/feedback/feedback.reducer';
import { getMaterialLabel } from '../../../dashboard-page/supervision';
import {
  ManufacturingOrder,
  ManufacturingOrderComponent
} from '../../../../../models/manufacturing-order';
import { splitOrderNumber } from '../../../../../features/order-manager/teeth-map/utils';
import { isCategoryProvisional } from '../../../../../features/order-form/utils';
import { getMaterialColor, isInManufacturingPendingStatus } from '../../../../../utils/order.utils';
import { AppDispatch } from '../../../../../store';
import { getColumnOptions } from '../../../../../features/datagrid/datagrid.utils';
import { Order } from '../../../../../models/order.tsx';
import { ManufacturingTabPermissions } from '../../../../../models/connected-user.tsx';

export const colComponentType = (commonTypes: CommonTypes): TypeColWithNamePropertyPlatform => {
  return {
    ...getColumnOptions(
      'componentType',
      i18next.t('datagrid.columns.componentType', { ns: 'production' }),
      2,
      false
    ),
    minWidth: 80,
    maxWidth: 150,
    showColumnMenuTool: false,
    filterEditor: SelectFilter,
    filterEditorProps: {
      multiple: true,
      wrapMultiple: false,
      dataSource: Object.values(commonTypes?.componentTypes).map((componentTypeKey) => ({
        id: componentTypeKey,
        label: i18next.t(`componentType.${componentTypeKey}`, { ns: 'component' })
      }))
    },
    cellDOMProps: () => ({
      style: {
        textAlign: 'left'
      }
    }),
    render: ({ value, data }) => {
      if (value) {
        // Display quantity only for tooth components, deducted with teethPositions
        const quantity =
          data.teethPositions?.length > 1 && value === ComponentType.TOOTH
            ? `(x${data.teethPositions.length})`
            : undefined;
        const translatedValue = i18next.t(
          `componentType.${value}${quantity ? '_plural.counter' : ''}`,
          {
            count: quantity ? +quantity : 0,
            ns: 'component'
          }
        );
        return (
          <div className={productionPageStyles['production-page__datagrid__column__type']}>
            <Text label={translatedValue} />
            {quantity && <Text bold={true} label={quantity.toLowerCase()} />}
          </div>
        );
      }
      return '-';
    }
  };
};

export const colFamily = (commonTypes: CommonTypes): TypeColWithNamePropertyPlatform => {
  return {
    ...getColumnOptions(
      'family',
      i18next.t('datagrid.columns.family', { ns: 'production' }),
      2,
      false
    ),
    minWidth: 80,
    maxWidth: 80,
    showColumnMenuTool: false,
    textEllipsis: false,
    cellDOMProps: () => ({
      style: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: '4px',
        alignItems: 'center'
      }
    }),
    filterEditor: SelectFilter,
    filterEditorProps: {
      multiple: true,
      wrapMultiple: false,
      dataSource:
        commonTypes.families &&
        Object.values(commonTypes.families).map((family) => {
          return {
            id: family,
            label: i18next.t(`families.${family}`, {
              ns: 'catalog'
            })
          };
        })
    },
    render: ({ value }) => {
      if (value) {
        return (
          <Tooltip>
            <TooltipContent>
              {i18next.t(`families.${value}`, {
                ns: 'catalog'
              })}
            </TooltipContent>
            <Chips
              className={styles['datagrid-feature__chips']}
              key={value.toLowerCase()}
              firstLabel={i18next
                .t(`families.${value}`, {
                  ns: 'catalog'
                })
                .substring(0, 1)}
              color={ColorPropsEnum[`FAMILY_${value.toUpperCase()}` as keyof typeof ColorPropsEnum]}
            />
          </Tooltip>
        );
      }
    }
  };
};

export const colManufactured = (
  connectedUserPermissions: ManufacturingTabPermissions,
  handleManufacturingStepActionCallback: (data: ManufacturingComponentView) => Promise<void>,
  handlePreviousStepActionCallback: (data: ManufacturingComponentView) => Promise<void>
): TypeColWithNamePropertyPlatform => {
  return {
    ...getColumnOptions(
      'manufacturingDate',
      i18next.t('datagrid.columns.manufacturing', { ns: 'production' }),
      3
    ),
    minWidth: 500,
    showColumnMenuTool: false,
    filterEditor: DateFilter,
    filterEditorProps: () => {
      // for range and not in range operators, the index is 1 for the after field
      return {
        dateFormat: i18next.t('date.small', { ns: 'common' }),
        cancelButton: false
      };
    },
    cellDOMProps: () => ({
      style: {
        textAlign: 'left',
        textTransform: 'capitalize'
      }
    }),
    render: ({ data }: { data: ManufacturingComponentView }) => {
      return (
        <Timeline alignOtherChildrenLeft={true}>
          {Object.keys(manufacturingStepDisplay).map((step: string, i: number) => {
            const isPending = isPendingStep(data.manufacturingStep, step);
            const isInProgress = isInProgressStep(data.manufacturingStep, step);
            const isSuccess = isSuccessStep(data.manufacturingStep, step);
            let state = 'pending';
            if (isInProgress) {
              state = 'inProgress';
            } else if (isSuccess) {
              state = 'success';
            }
            return (
              <TimelineItem
                key={`${data.id}-${step}`}
                data-key={`comp-${data.id}-${step}`}
                isInProgress={isInProgress}
                isSuccess={isSuccess}
                isPending={isPending}
                isFirstItem={i === 0}
                customDividerWidth={40}
                customLabelWidth={i === 0 ? 65 : 92}
                isInProgressIcon={faArrowsRotate}
                label={
                  step === manufacturingStepKeysEnum.DESIGN ? (
                    <Tooltip>
                      <TooltipContent>
                        {i18next.t(`order.manufacturingStatus.${step.toLowerCase()}.description`, {
                          ns: 'common'
                        })}
                      </TooltipContent>
                      {i18next.t(`order.manufacturingStatus.${step.toLowerCase()}.${state}`, {
                        ns: 'common'
                      })}
                    </Tooltip>
                  ) : (
                    i18next.t(`order.manufacturingStatus.${step.toLowerCase()}.${state}`, {
                      ns: 'common'
                    })
                  )
                }
                data={data}></TimelineItem>
            );
          })}
          {connectedUserPermissions.canPerformManufacturingActions && (
            <StepActions
              component={data}
              callbackNextStep={handleManufacturingStepActionCallback}
              callbackPreviousStep={handlePreviousStepActionCallback}
              isLoading={isInManufacturingPendingStatus(data)}
            />
          )}
        </Timeline>
      );
    }
  };
};

export const colFiles = (
  connectedUserPermissions: ManufacturingTabPermissions,
  handleDownloadCallback: (data: ManufacturingComponentView) => void,
  isDownloadingFiles: boolean
): TypeColWithNamePropertyPlatform | undefined => {
  if (!connectedUserPermissions?.canPerformManufacturingActions) {
    return;
  }

  return {
    ...getColumnOptions(
      'files',
      i18next.t('datagrid.columns.files', { ns: 'production' }),
      2,
      false
    ),
    minWidth: 70,
    maxWidth: 110,
    showColumnMenuTool: false,
    render: ({ data }) => {
      return (
        <div
          className={productionPageStyles['production-page__datagrid__column__file']}
          data-key={`comp-${data.id}-actions-files`}>
          <Tooltip key={`tooltip`}>
            <TooltipContent>
              {isInManufacturingPendingStatus(data) ? (
                <>{i18next.t('pendingElement.tooltipContent', { ns: 'common' })}</>
              ) : (
                <>{i18next.t('tabs.manufacture.download_tooltip', { ns: 'production' })}</>
              )}
            </TooltipContent>
            <IconButton
              faIconClass="download"
              onClick={() => handleDownloadCallback(data)}
              iconSize="xl"
              radius="full"
              color={ColorPropsEnum.PRIMARY}
              isDisabled={isDownloadingFiles || isInManufacturingPendingStatus(data)}
              className={styles['production-page__datagrid__icon-button']}
            />
          </Tooltip>
          <Tooltip key={`tooltip-view-file`}>
            <TooltipContent>
              {isInManufacturingPendingStatus(data) ? (
                <>{i18next.t('pendingElement.tooltipContent', { ns: 'common' })}</>
              ) : (
                <>{i18next.t('tabs.manufacture.view_tooltip', { ns: 'production' })}</>
              )}
            </TooltipContent>
            <IconButton
              faIconClass="eye"
              iconSize="xl"
              radius="full"
              color={ColorPropsEnum.PRIMARY}
              // When enabling this, isDisabled should, at least, be isInManufacturingPendingStatus(data)
              isDisabled={true}
              className={styles['production-page__datagrid__icon-button']}
            />
          </Tooltip>
        </div>
      );
    }
  };
};

export const colShade = (commonTypes: CommonTypes): TypeColWithNamePropertyPlatform => {
  return {
    ...getColumnOptions(
      'shade',
      i18next.t('datagrid.columns.shade', { ns: 'production' }),
      2,
      false
    ),
    showColumnMenuTool: false,
    maxWidth: 75,
    filterEditor: SelectFilter,
    filterEditorProps: {
      multiple: true,
      wrapMultiple: false,
      dataSource: commonTypes?.shades?.map((shade) => ({
        id: shade.code,
        label: i18next.t(`shade.${shade.code}`, { ns: 'component' })
      }))
    },
    cellDOMProps: () => ({
      style: {
        textAlign: 'left',
        textTransform: 'capitalize'
      }
    }),
    render: ({ value }) => {
      return value ? (
        <Chips
          className={styles['datagrid-feature__chips']}
          color={ColorPropsEnum[value as keyof typeof ColorPropsEnum]}
          firstLabel={i18next.t(`shade.${value.toUpperCase()}`, { ns: 'component' })}
        />
      ) : (
        '-'
      );
    }
  };
};

export const colMaterial = (commonTypes: CommonTypes): TypeColWithNamePropertyPlatform => {
  return {
    ...getColumnOptions(
      'material',
      i18next.t('datagrid.columns.material', { ns: 'production' }),
      2,
      false
    ),
    showColumnMenuTool: false,
    filterEditor: SelectFilter,
    maxWidth: 130,
    filterEditorProps: {
      multiple: true,
      wrapMultiple: false,
      dataSource: [...new Set(commonTypes?.materials.map((material) => material.code))].map(
        (material) => ({
          id: material,
          label: i18next.t(`material.${material}`, { ns: 'component' })
        })
      )
    },
    cellDOMProps: () => ({
      style: {
        textAlign: 'left',
        textTransform: 'capitalize'
      }
    }),
    render: ({ value, data }) => {
      const materialLabel = getMaterialLabel(value, data.materialStratification);
      return value ? (
        <Chips
          className={styles['datagrid-feature__chips']}
          firstLabel={materialLabel}
          color={getMaterialColor(value)}
        />
      ) : (
        '-'
      );
    }
  };
};

export const colManufacturingProcess: TypeColWithNamePropertyPlatform = {
  ...getColumnOptions(
    'manufacturingProcess',
    i18next.t('datagrid.columns.productionType', { ns: 'production' }),
    2,
    false
  ),
  defaultFlex: 1,
  showColumnMenuTool: false,
  filterEditor: SelectFilter,
  filterEditorProps: {
    multiple: false,
    wrapMultiple: false,
    dataSource: Object.values(ManufacturingProcessEnum)
      .filter((value) => value !== ManufacturingProcessEnum.CASTING)
      .map((manufacturingProcess) => ({
        id: manufacturingProcess,
        label: i18next.t(`productionType.${manufacturingProcess}`, {
          ns: 'component'
        })
      }))
  },
  cellDOMProps: () => ({
    style: {
      textAlign: 'left',
      textTransform: 'capitalize'
    }
  }),
  render: ({ value }) => {
    const key: keyof typeof ManufacturingProcessEnum = value;
    const manufacturingProcess = Object.keys(ManufacturingProcessEnum).includes(value)
      ? i18next.t(`productionType.${ManufacturingProcessEnum[key]}`, {
          ns: 'component'
        })
      : '-';

    return <p>{manufacturingProcess}</p>;
  }
};

export const colAssignee = <T extends ManufacturingComponentView>(
  isActionAllowed: boolean,
  handleClickAssign: (data: T) => void
): TypeColWithNamePropertyPlatform => {
  return {
    ...getColumnOptions(
      'assigneeName',
      i18next.t('datagrid.columns.assignee', { ns: 'production' })
    ),
    showColumnMenuTool: false,
    defaultFlex: 1,
    cellDOMProps: () => ({
      style: {
        textAlign: 'left',
        textTransform: 'capitalize'
      }
    }),
    render: ({ data, value }: { data: T; value: string }) => {
      if (value) {
        return value;
      } else if (isActionAllowed) {
        const displayedButton = (
          <Button
            data-cy="datagrid-assign-button"
            label={i18next.t('datagrid.action.assign', { ns: 'production' })}
            onClick={() => handleClickAssign(data)}
            size="s"
            category="secondary"
            isDisabled={isInManufacturingPendingStatus(data)}
          />
        );
        if (isInManufacturingPendingStatus(data)) {
          return (
            <Tooltip>
              <TooltipContent>
                {i18next.t('pendingElement.tooltipContent', { ns: 'common' })}
              </TooltipContent>
              {displayedButton}
            </Tooltip>
          );
        } else {
          return displayedButton;
        }
      } else {
        return '-';
      }
    }
  };
};

export const colComponentOrderNumber = <
  T extends Order | ManufacturingOrder | ManufacturingComponentView
>(
  dispatch: AppDispatch,
  actionBtn?: ActionBtn<T>
): TypeColWithNamePropertyPlatform => {
  return {
    ...getColumnOptions(
      'orderNumber',
      i18next.t('datagrid.columns.manufacturingOrderNumber', { ns: 'production' }),
      1
    ),
    type: 'number',
    headerAlign: 'start',
    showColumnMenuTool: false,
    minWidth: 180,
    maxWidth: 180,
    render: ({ data, value }) => {
      const orderNumberSplit = splitOrderNumber(value);
      return (
        <div
          className={
            styles[
              data.isInError
                ? 'datagrid-feature__orderNumber--error'
                : 'datagrid-feature__orderNumber'
            ]
          }>
          <Link href={`/order/${value}/detail`}>
            {orderNumberSplit.beforeLastPart}
            <span style={{ fontWeight: 'bold' }}>{orderNumberSplit.lastPart}</span>
          </Link>
          <IconButton
            color={ColorPropsEnum.GREY}
            iconSize={'sm'}
            faIconClass="copy"
            iconStyle="regular"
            onClick={() => {
              navigator.clipboard.writeText(value);
              dispatch(
                feedbackActions.setToast({
                  message: i18next.t('toast.copiedToClipboard', {
                    orderNumber: value,
                    ns: 'common'
                  }),
                  type: ToastType.SUCCESS
                })
              );
            }}
            radius="full"
          />
          {actionBtn && data.commentExists && (
            <IconButton
              data-cy="add-comment"
              faIconClass="comment"
              iconStyle="regular"
              onClick={() => {
                actionBtn.onClick(data);
              }}
              radius="full"
            />
          )}
        </div>
      );
    }
  };
};

export const colProduct: TypeColWithNamePropertyPlatform = {
  ...getColumnOptions(
    'productId',
    i18next.t('datagrid.columns.product', { ns: 'production' }),
    2,
    false
  ),
  showColumnMenuTool: false,
  minWidth: 60,
  maxWidth: 80,
  cellDOMProps: () => ({
    style: {
      textAlign: 'left',
      display: 'flex',
      flexWrap: 'wrap',
      paddingTop: 0,
      paddingBottom: 0
    }
  }),
  render: ({ data }) => {
    if (data) {
      const productData = computeProductDisplay(data);
      return (
        <Tooltip key={`prd__${data.id}__${productData?.label.toLowerCase().replaceAll(' ', '_')}`}>
          <TooltipContent>{productData?.label}</TooltipContent>
          <BubblePicto
            url={productData?.imageUrl}
            isDashedBorder={productData?.category && isCategoryProvisional(productData.category)}
            color={
              ColorPropsEnum[
                `FAMILY_${productData?.family
                  .toUpperCase()
                  .toUpperCase()}` as keyof typeof ColorPropsEnum
              ]
            }
          />
        </Tooltip>
      );
    }
  }
};

const computeProductDisplay = (
  component: ManufacturingComponentView
): ProductForDatagrid | undefined => {
  if (component.productId && component.family) {
    return {
      count: 1,
      label: component[
        getLocalizedProperty('productLabel') as keyof ManufacturingComponentView
      ] as string,
      imageUrl: component.productImageUrl,
      family: component.family,
      category: component.productCategory
    };
  }
};

const getManufacturingProcessActions = (
  data: ManufacturingComponentView,
  handleClickSetManufacturingProcess: (
    data: ManufacturingComponentView,
    manufacturingProcess: ManufacturingProcessEnum
  ) => void
): MenuEntry[] => {
  switch (data.manufacturingProcess) {
    case ManufacturingProcessEnum.MILLING:
      return getManufacturingProcessEntryActions(
        data,
        [ManufacturingProcessEnum.PRINT],
        handleClickSetManufacturingProcess
      );
    case ManufacturingProcessEnum.PRINT:
      return getManufacturingProcessEntryActions(
        data,
        [ManufacturingProcessEnum.MILLING],
        handleClickSetManufacturingProcess
      );
    default:
      return getManufacturingProcessEntryActions(
        data,
        [ManufacturingProcessEnum.MILLING, ManufacturingProcessEnum.PRINT],
        handleClickSetManufacturingProcess
      );
  }
};

export const colManufacturingMenu = (
  canPerformManufacturingActions: boolean,
  handleClickSetManufacturingProcess: (
    data: ManufacturingComponentView,
    manufacturingProcess: ManufacturingProcessEnum
  ) => void,
  handleClickAssign: (component: ManufacturingComponentView) => void,
  handleClickUnassign: (component: ManufacturingComponentView) => Promise<void>,
  handleCommentCallback: (data: ManufacturingComponentView) => void
): TypeColWithNamePropertyPlatform | undefined => {
  // Do not display column if it's empty
  if (!canPerformManufacturingActions) {
    return;
  }

  return {
    ...getColumnOptions('menu', '', 1, false),
    showColumnMenuTool: false,
    maxWidth: 60,
    render: ({ data }) => {
      const allowedActionsForUser = [
        getManufacturingProcessActions(data, handleClickSetManufacturingProcess),
        [
          !data.assigneeEmail && !data.assigneeName
            ? assignManufacturingOrderAction(data, handleClickAssign)
            : unAssignManufacturingOrderAction(data, handleClickUnassign),
          commentsOrderAction(data, handleCommentCallback)
        ]
      ];

      const kebabMenu = (
        <DropdownMenu
          renderTargetButton={({ active }: { active: boolean }) => (
            <IconButton
              data-cy="datagrid-menu"
              faIconClass="ellipsis-vertical"
              isActive={active}
              isDisabled={isInManufacturingPendingStatus(data)}
              radius="full"
            />
          )}
          data={allowedActionsForUser}
        />
      );
      if (isInManufacturingPendingStatus(data)) {
        return (
          <Tooltip>
            <TooltipContent>
              {i18next.t('pendingElement.tooltipContent', { ns: 'common' })}
            </TooltipContent>
            {kebabMenu}
          </Tooltip>
        );
      } else {
        return kebabMenu;
      }
    }
  };
};

// Build filters for component datagrid
export const buildManufacturingComponentsFilters = (filterValue?: Array<FilterProps>): string => {
  let filters = `&filter.currentStep=$in:${WorkflowStepDatagridFilter.MANUFACTURING}&filter.isChairSide=0`;

  const isManufacturingProcessFilter =
    filterValue?.find((filter) => filter.name === 'manufacturingProcess')?.value === null;
  if (isManufacturingProcessFilter) {
    const manufacturingProcessWithoutCastingFilter = `&filter.manufacturingProcess=$in:${[ManufacturingProcessEnum.MILLING, ManufacturingProcessEnum.PRINT].join(',')}&filter.manufacturingProcess=$or:$null`;
    filters = `${filters}${manufacturingProcessWithoutCastingFilter}`;
  }

  filterValue
    ?.filter((filter) => filter.value)
    .forEach((filter) => {
      switch (filter.name) {
        case 'manufacturingDate': {
          const isoDate = moment(
            filter.value,
            i18next.t('date.small', { ns: 'common' })
          ).toISOString();
          filters = `${filters}&filter.${filter.name}=$gte:${isoDate}`;
          break;
        }
        case 'shade':
        case 'componentType':
        case 'material':
        case 'family': {
          // multi select filter
          const multiSelectValues = filter.value as Array<string>;
          filters = `${filters}&filter.${filter.name}=$in:${multiSelectValues.join(',')}`;
          break;
        }
        case 'manufacturingProcess': {
          filters = `${filters}&filter.${filter.name}=${filter.value}`;
          break;
        }
        default:
          filters = `${filters}${getUrlEncodedFilter(filter)}`;
          break;
      }
    });
  return filters;
};

export const rowClassname = ({
  data,
  props
}: {
  data: ManufacturingOrderComponent;
  props: { dataSourceArray: ManufacturingComponentView[] };
}): string => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore this is an exception because props is an unknown property.

  // Here, we get all components per order
  // We want to display all components belonging to the same order in the same background color
  // In order to do that, we need to override "zebra" lines in inovua
  // And compute manually the background color
  // The last component also has a border to make the separation more obvious
  const componentsInOrder = props.dataSourceArray.filter(
    (component) => component.orderNumber === data.orderNumber
  );
  const orderNumbers = Array.from(
    new Set(props.dataSourceArray.map((component) => component.orderNumber))
  );
  const isEvenOrderIndex = orderNumbers.indexOf(data.orderNumber) % 2 === 0;
  const isLastComponentInOrder =
    componentsInOrder.findIndex((component) => component.id === data.id) ===
    componentsInOrder.length - 1;
  const classNames = [];

  // Urgent orders that are to be manufactured will always be displayed with a red bakcground
  if ((data.isUrgent && data.toManufacture) || data.isInError || !data.toManufacture) {
    classNames.push(styles['datagrid-feature__row--danger']);

    if (isLastComponentInOrder) {
      classNames.push(styles['datagrid-feature__row--with-border']);
    }
  } else {
    classNames.push(
      isEvenOrderIndex
        ? styles['datagrid-feature__row--light']
        : styles['datagrid-feature__row--dark']
    );

    if (isLastComponentInOrder) {
      classNames.push(styles['datagrid-feature__row--with-border']);
    }
  }

  return classNames.join(' ');
};
