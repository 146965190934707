export enum DiagnosticProperties {
  TEETH_TO_BE_EXTRACTED = 'teethToBeExtracted',
  MISSING_TEETH = 'missingTeeth'
}

export enum RejectCause {
  FILES_QUALITY = 'FILES_QUALITY',
  FILES_LABEL = 'FILES_LABEL',
  PROTOCOL = 'PROTOCOL',
  PRESCRIPTION = 'PRESCRIPTION',
  INSTRUCTIONS = 'INSTRUCTIONS'
}

export enum DeliveryDelayEnum {
  DEFAULT = 2
}
